/*eslint-disable*/
import React, { Fragment, useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, useHistory, useLocation } from "react-router-dom";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import CropFreeIcon from "@material-ui/icons/CropFree";
import Person from "@material-ui/icons/Person";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import SubjectIcon from "@material-ui/icons/Subject";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import SettingsIcon from "@material-ui/icons/Settings";
import BarChartIcon from "@material-ui/icons/BarChart";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
// import CreditCardIcon from "@material-ui/icons/CreditCard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import TimerIcon from "@material-ui/icons/Timer";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import SmsIcon from "@material-ui/icons/Sms";
import AnnouncementIcon from "@material-ui/icons/Announcement";
// core components
// import AdminNavbarLinks from "./AdminNavbarLinks.js";

import styles from "../assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { Typography } from "@material-ui/core";
import { Collapse } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { checkPermissions } from "../utils/helpers";
import { systemRoles } from "../utils/helpers.js";
import LogoutModal from "./components/LogoutModal";
import * as userService from "../modules/admin/user/service";

const useStyles = makeStyles(styles);
const adminPrefix = "/admin";

export default function Sidebar(props) {
  const classes = useStyles();
  let location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const informations = useSelector((state) => state.informations.data);
  const user = useSelector((state) => state.user.data);

  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  const [openSubLinks, setOpenSubLinks] = useState({});

  useEffect(() => {
    const pathname = location.pathname;
    setOpenSubLinks(() => ({
      [pathname.split("/")[2]]: true,
    }));

    if (user.info && user.info.is_need_update) {
      history.push("/admin/user");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (user.info && user.info.is_need_update) {
      history.push("/admin/user");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleLogout = () => {
    dispatch(userService.logout());
  };

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName, name = "") {
    if (name) {
      return location.pathname.toLowerCase().includes(name.toLowerCase());
    }

    return location.pathname === routeName;
  }

  const getNotifNumber = (value) => {
    let tempValue = value;
    if (parseInt(value) > 99) {
      tempValue = "99+";
    }

    if (value === 0) {
      return "";
    }

    return <span className={classes.notifications}>{tempValue}</span>;
  };

  const handleOpenSubLinks = (name) => {
    setOpenSubLinks((prev) => ({
      ...prev,
      [name]: !!!openSubLinks[name],
    }));
  };

  const { color, logo, logoText } = props;

  const linkRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: Dashboard,
      allowedRoles: ["*"],
    },
    {
      path: "/admission",
      name: "Admission",
      icon: BarChartIcon,
      allowedRoles: [systemRoles.administrator, systemRoles.registrar],
      children: [
        {
          path: "/college",
          name: "College",
          icon: BarChartIcon,
          allowedRoles: [systemRoles.administrator, systemRoles.registrar],
        },
        // {
        //   path: "/high-school",
        //   name: "High School",
        //   icon: BarChartIcon,
        //   // icon_class: classes.rejected,
        // allowedRoles: [systemRoles.administrator, systemRoles.registrar],
        // },
      ],
    },
    {
      path: "/qrcode-scanner",
      name: "QrCode Scanner",
      icon: CropFreeIcon,
      allowedRoles: [systemRoles.administrator, systemRoles.registrar],
    },
    {
      path: "/announcements",
      name: "Announcement",
      icon: AnnouncementIcon,
      allowedRoles: [
        systemRoles.administrator,
        systemRoles.registrar,
        systemRoles.school_admin,
      ],
    },

    {
      path: "/attendance",
      name: "Attendance",
      icon: TimerIcon,
      allowedRoles: [systemRoles.administrator, systemRoles.guard],
    },
    {
      path: "/identification-number",
      name: "Student ID Management",
      icon: BarChartIcon,
    },
    {
      path: "/id cards",
      name: "Id Cards",
      icon: BarChartIcon,
      allowedRoles: [systemRoles.administrator],
      children: [
        {
          path: "/students",
          name: "Students",
          icon: Person,
          allowedRoles: [systemRoles.administrator],
        },
        {
          path: "/employees",
          name: "Faculty & Staff",
          icon: Person,
          allowedRoles: [systemRoles.administrator],
        },
      ],
    },
    {
      path: "/user",
      name: "User Profile",
      icon: Person,
      allowedRoles: ["*"],
    },

    {
      path: "/enrollments",
      name: "Enrollments",
      icon: BarChartIcon,
      allowedRoles: [systemRoles.administrator, systemRoles.registrar],
      children: [
        {
          path: "/list",
          name: (
            <Fragment>
              All Enrollments
              {getNotifNumber(informations.total || 0)}
            </Fragment>
          ),
          icon: SupervisedUserCircleIcon,
          allowedRoles: [systemRoles.administrator, systemRoles.registrar],
        },
        {
          path: "/new-request",
          name: (
            <Fragment>
              New Request
              {getNotifNumber(informations.new_request || 0)}
            </Fragment>
          ),
          icon: ControlPointIcon,
          allowedRoles: [systemRoles.administrator, systemRoles.registrar],
        },
        {
          path: "/for-enrollments",
          name: (
            <Fragment>
              For Enrollments
              {getNotifNumber(informations.for_enrollment || 0)}
            </Fragment>
          ),
          icon: AssignmentReturnedIcon,
          allowedRoles: [systemRoles.administrator, systemRoles.registrar],
        },
        {
          path: "/rejected",
          name: (
            <Fragment>
              Rejected
              {getNotifNumber(informations.rejected || 0)}
            </Fragment>
          ),
          icon: HighlightOffIcon,
          allowedRoles: [systemRoles.administrator, systemRoles.registrar],
        },
        {
          path: "/cashier-hold",
          name: (
            <Fragment>
              {`Cashier's Hold`}
              {getNotifNumber(informations.on_hold || 0)}
            </Fragment>
          ),
          icon: AssignmentIndIcon,
          allowedRoles: [systemRoles.administrator, systemRoles.registrar],
        },

        {
          path: "/enrolled",
          name: "Enrolled",
          icon: AssignmentTurnedInIcon,
          allowedRoles: [systemRoles.administrator, systemRoles.registrar],
        },
      ],
    },

    {
      path: "/reports",
      name: "Reports",
      icon: EqualizerIcon,
      allowedRoles: [
        systemRoles.administrator,
        systemRoles.school_admin,
        systemRoles.registrar,
      ],
      children: [
        {
          path: "/enrollments-statistics",
          name: "Enrollments",
          icon: EqualizerIcon,
          allowedRoles: [
            systemRoles.administrator,
            systemRoles.school_admin,
            systemRoles.registrar,
          ],
        },
        {
          path: "/cashiers",
          name: "Cashier's Reports",
          icon: ReceiptIcon,
          allowedRoles: [
            systemRoles.administrator,
            systemRoles.school_admin,
            systemRoles.cashier,
          ],
        },
      ],
    },
    {
      path: "/employees",
      name: "Faculty",
      icon: SupervisedUserCircleIcon,
      allowedRoles: [
        systemRoles.administrator,
        systemRoles.registrar,
        systemRoles.school_admin,
      ],
    },
    {
      path: "/subjects",
      name: "Subjects",
      icon: MenuBookIcon,
      allowedRoles: [systemRoles.administrator, systemRoles.registrar],
    },

    {
      path: "/students",
      name: "Students",
      icon: SupervisedUserCircleIcon,
      allowedRoles: [systemRoles.administrator, systemRoles.registrar],
    },

    {
      path: "/accounts",
      name: "Accounts",
      icon: AccountBoxIcon,
      allowedRoles: [systemRoles.administrator, systemRoles.cashier],
    },
    {
      path: "/payments",
      name: "Payments",
      icon: ReceiptIcon,
      allowedRoles: [systemRoles.administrator, systemRoles.cashier],
    },

    // {
    //   path: "/billing",
    //   name: "Billing",
    //   icon: CreditCardIcon,
    //   allowedRoles: [systemRoles.administrator],
    // },

    {
      path: "/sms",
      name: "SMS",
      icon: SmsIcon,
      allowedRoles: [systemRoles.administrator],
    },
    {
      path: "/users",
      name: "Users",
      icon: SupervisedUserCircleIcon,
      allowedRoles: [systemRoles.administrator],
    },

    {
      name: "Settings",
      path: "/settings",
      icon: SettingsIcon,
      allowedRoles: [systemRoles.administrator, systemRoles.registrar],
      children: [
        {
          path: "/roles-permission",
          name: "Roles Permission",
          icon: SupervisedUserCircleIcon,
          allowedRoles: [systemRoles.administrator],
        },

        {
          path: "/system",
          name: "System",
          icon: SettingsIcon,
          allowedRoles: [systemRoles.administrator],
        },
        {
          name: "Courses",
          path: "/courses",
          icon: AccountBalanceIcon,
          allowedRoles: [systemRoles.administrator, systemRoles.registrar],
        },
        {
          path: "/prospectus",
          name: "Prospectus",
          icon: SubjectIcon,
          allowedRoles: [systemRoles.administrator, systemRoles.registrar],
        },
        {
          name: "Subject Offer",
          path: "/subject-offer",
          icon: LocalOfferIcon,
          allowedRoles: [systemRoles.administrator, systemRoles.registrar],
        },
        {
          path: "/subject-categories",
          name: "Subject Categories",
          icon: MenuBookIcon,
          allowedRoles: [systemRoles.administrator, systemRoles.registrar],
        },

        {
          path: "/duplicate-users",
          name: "Duplicate Users",
          icon: SupervisedUserCircleIcon,
          allowedRoles: [systemRoles.administrator],
        },
        {
          name: "Fees",
          path: "/fees",
          icon: AttachMoneyIcon,
          allowedRoles: [systemRoles.administrator, systemRoles.cashier],
        },
        {
          path: "/password-reset",
          name: "Password Reset",
          icon: VpnKeyIcon,
          allowedRoles: [systemRoles.administrator],
        },
      ],
    },

    // instructor
    {
      name: "Subjects Loads",
      path: "/instructor/subject-loads",
      icon: AssignmentReturnedIcon,
      allowedRoles: [systemRoles.full_time, systemRoles.part_time],
      divider: "My Menu",
    },

    {
      name: "eClearance",
      path: "/instructor/e-clearance",
      icon: AssignmentTurnedInIcon,
      allowedRoles: [systemRoles.full_time, systemRoles.part_time],
    },
    {
      name: "Grades",
      path: "/instructor/grades",
      icon: BarChartIcon,
      allowedRoles: [systemRoles.full_time, systemRoles.part_time],
    },

    // student links
    {
      name: "Subjects",
      path: "/student/subjects",
      icon: ScheduleIcon,
      allowedRoles: [systemRoles.student],
      divider: "My Menu",
    },
    {
      name: "eClearance",
      path: "/student/eclearance",
      icon: ArtTrackIcon,
      allowedRoles: [systemRoles.student],
    },
    {
      name: "Grades",
      path: "/student/grades",
      icon: BarChartIcon,
      allowedRoles: [systemRoles.student],
    },
    // {
    //   name: "Evaluation",
    //   path: "/student/evaluation",
    //   icon: ArtTrackIcon,
    //   allowedRoles: [systemRoles.student],
    // },
    {
      name: "Billing",
      path: "/student/billing",
      icon: ReceiptIcon,
      allowedRoles: [systemRoles.student],
    },
  ];

  const handleNavigate = () => {
    if (props.open) {
      props.handleDrawerToggle();
    }
  };

  const renderRoutes = () => {
    return linkRoutes
      .filter((link) => checkPermissions(link.path, user))
      .map(({ children = [], ...prop }, key) => {
        var activePro = " ";
        var listItemClasses;

        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(
            adminPrefix + prop.path,
            children.length > 0 ? prop.name : ""
          ),
        });

        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(adminPrefix + prop.path),
        });

        return children.length > 0 ? (
          <Fragment key={key}>
            <ListItem
              button
              className={classNames(classes.itemLink, classes.item, {
                [" " + classes[color]]: location.pathname.includes(prop.path),
              })}
              style={
                (openSubLinks[prop.name.toLowerCase()] && {
                  marginBottom: 6,
                }) ||
                {}
              }
              onClick={() => handleOpenSubLinks(prop.name.toLowerCase())}
            >
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                />
              )}
              <ListItemText
                primary={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography style={{ fontWeight: 300, fontSize: 16 }}>
                      {prop.name}
                    </Typography>
                    {openSubLinks[prop.name.toLowerCase()] ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>
                }
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography
              />
            </ListItem>
            <div style={{ paddingLeft: 15 }}>
              <Collapse
                in={openSubLinks[prop.name.toLowerCase()]}
                timiout="auto"
                unmountOnExit
              >
                {children
                  .filter((child) =>
                    checkPermissions(prop.path + child.path, user)
                  )

                  .map((child, i) => {
                    var listItemChildClasses;

                    listItemChildClasses = classNames({
                      [" " + classes[color]]: activeRoute(
                        adminPrefix + prop.path + child.path
                      ),
                    });

                    return (
                      <NavLink
                        to={adminPrefix + prop.path + child.path}
                        className={activePro + classes.item}
                        activeClassName="active"
                        key={i}
                      >
                        <ListItem
                          button
                          onClick={handleNavigate}
                          className={
                            classes.childrenItemLink + listItemChildClasses
                          }
                        >
                          {typeof child.icon === "string" ? (
                            <Icon
                              className={classNames(
                                classes.itemIcon,
                                whiteFontClasses
                              )}
                            >
                              {child.icon}
                            </Icon>
                          ) : (
                            <child.icon
                              className={classNames(
                                classes.itemIcon,
                                whiteFontClasses,
                                child.icon_class
                              )}
                            />
                          )}
                          <ListItemText
                            primary={child.name}
                            className={classNames(
                              classes.itemText,
                              whiteFontClasses
                            )}
                            disableTypography={true}
                          />
                        </ListItem>
                      </NavLink>
                    );
                  })}
              </Collapse>
            </div>
          </Fragment>
        ) : (
          <NavLink
            to={adminPrefix + prop.path}
            className={activePro + classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem
              button
              onClick={handleNavigate}
              className={classes.itemLink + listItemClasses}
            >
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                >
                  {prop.icon}
                </Icon>
              ) : (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                />
              )}
              <ListItemText
                primary={prop.name}
                className={classNames(classes.itemText, whiteFontClasses)}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      });
  };

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={classes.logo}>
            <a href="/" className={classNames(classes.logoLink)}>
              <div className={classes.logoImage}>
                <img src={logo} alt="logo" className={classes.img} />
              </div>
              {logoText}
            </a>
          </div>
          <div className={classes.sidebarWrapper}>
            <List className={classes.list}>
              <ListItem
                button
                onClick={() => setOpenLogoutModal(true)}
                className={classes.itemLink}
              >
                <Icon className={classNames(classes.itemIcon)}>
                  <ExitToAppIcon />
                </Icon>
                <ListItemText
                  primary="Logout"
                  className={classNames(classes.itemText)}
                  disableTypography={true}
                />
              </ListItem>

              {renderRoutes()}
            </List>
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          <div className={classes.logo}>
            <a href="/" className={classNames(classes.logoLink)}>
              <div className={classes.logoImage}>
                <img src={logo} alt="logo" className={classes.img} />
              </div>
              {logoText}
            </a>
          </div>
          <div className={classes.sidebarWrapper}>
            <List className={classes.list}>{renderRoutes()}</List>
          </div>
        </Drawer>
      </Hidden>

      <LogoutModal
        open={openLogoutModal}
        onClose={() => setOpenLogoutModal(false)}
        handleLogout={handleLogout}
      />
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  open: PropTypes.bool,
};
