import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormField from "../../../../components/FormField";
import FormSelectField from "../../../../components/FormSelectField";
import { schoolYearOptions, semesterOptions } from "../../../../utils/helpers";
import * as service from "../service";

function Setting() {
  const settings = useSelector((state) => state.settings);
  const currentSettings = settings.data;
  const dispatch = useDispatch();

  const [basicSetting, setBasicSetting] = useState(settings.data);

  useEffect(() => {
    setBasicSetting(currentSettings); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSettings]);

  const handleBasicSettingChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setBasicSetting((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSwitchChange = (name) => {
    setBasicSetting((prev) => ({
      ...prev,
      [name]: prev[name] === "1" ? "0" : "1",
    }));
  };

  const updateBasicSetting = () => {
    dispatch(service.updateBasicSettings(basicSetting));
  };

  const getSwitchData = (value) => {
    if (value === "1") {
      return true;
    }

    return false;
  };

  return (
    <Fragment>
      <Card>
        <CardContent>
          <Typography>System Settings</Typography>
          <Divider />
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <FormSelectField
                label="School Year"
                name="school_year"
                onChange={handleBasicSettingChange}
                value={basicSetting.school_year || ""}
                options={schoolYearOptions()}
                noOptions
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormSelectField
                label="Semester"
                name="semester"
                keyValuePair="id,label"
                onChange={handleBasicSettingChange}
                value={basicSetting.semester || ""}
                options={semesterOptions()}
                noOptions
              />
            </Grid>
          </Grid>
          <Box paddingTop={1}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Maintenance Mode</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={getSwitchData(basicSetting.maintenance)}
                      onChange={() => handleSwitchChange("maintenance")}
                    />
                  }
                  label={
                    getSwitchData(basicSetting.maintenance)
                      ? "Active"
                      : "Inactive"
                  }
                />
              </FormGroup>
            </FormControl>
          </Box>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography>Enrollment Settings</Typography>
          <Divider />
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <FormSelectField
                label="School Year"
                name="enrollment_school_year"
                onChange={handleBasicSettingChange}
                value={basicSetting.enrollment_school_year || ""}
                options={schoolYearOptions()}
                noOptions
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormSelectField
                label="Semester"
                name="enrollment_semester"
                keyValuePair="id,label"
                onChange={handleBasicSettingChange}
                value={basicSetting.enrollment_semester || ""}
                options={semesterOptions()}
                noOptions
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <FormField
                label="Enrollment Start"
                name="enrollment_start"
                onChange={handleBasicSettingChange}
                value={basicSetting.enrollment_start || ""}
                type="date"
                shrinkLabel
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormField
                label="Enrollment End"
                name="enrollment_end"
                onChange={handleBasicSettingChange}
                value={basicSetting.enrollment_end}
                type="date"
                inputProps={{ min: basicSetting.enrollment_start }}
                shrinkLabel
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormSelectField
                label="Status"
                name="enrollment_status"
                onChange={handleBasicSettingChange}
                value={basicSetting.enrollment_status || ""}
                options={["OPEN", "CLOSE"]}
                noOptions
              />
            </Grid>
          </Grid>

          <Box paddingTop={1}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Student Enrollment Type</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={getSwitchData(basicSetting.new_student)}
                      onChange={() => handleSwitchChange("new_student")}
                    />
                  }
                  label="New Student"
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={getSwitchData(basicSetting.old_student)}
                      onChange={() => handleSwitchChange("old_student")}
                    />
                  }
                  label="Old Student"
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={getSwitchData(basicSetting.returnee)}
                      onChange={() => handleSwitchChange("returnee")}
                    />
                  }
                  label="Returnee"
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={getSwitchData(basicSetting.transferee)}
                      onChange={() => handleSwitchChange("transferee")}
                    />
                  }
                  label="Transferee"
                />
              </FormGroup>
            </FormControl>
          </Box>
        </CardContent>
      </Card>
      <Box marginTop={2}>
        <FormControl margin="dense">
          <Button
            variant="contained"
            color="primary"
            onClick={updateBasicSetting}
            disabled={settings.updating}
          >
            {settings.updating ? <CircularProgress size={20} /> : "Update"}
          </Button>
        </FormControl>
      </Box>
    </Fragment>
  );
}

export default Setting;
