import React from "react";
import { Route, BrowserRouter as ReactRouter, Switch } from "react-router-dom";
import NotFound from "./modules/notfound/pages/NotFound";
import Admin from "./layouts/Admin";
import Public from "./route/Public";
import publicRoute from "./route/publicRoute";
import { Fragment } from "react";

function App() {
  return (
    <Fragment>
      <ReactRouter>
        <Switch>
          <Route path="/admin" component={Admin} />

          {publicRoute.map((route, i) => (
            <Public exact key={i} {...route} />
          ))}
          <Route component={NotFound} />
        </Switch>
      </ReactRouter>
    </Fragment>
  );
}

export default App;
