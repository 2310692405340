import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

import PropTypes from "prop-types";
import CustomPagination from "./CustomPagination";

const useStyles = makeStyles({
  container: {
    width: "100%",
    overflow: "auto",
  },
  indexColumn: {
    maxWidth: 36,
    padding: "6px 16px",
    "@media (max-width: 599px)": {
      fontSize: 14,
    },
  },
  indexPrintColumn: {
    fontSize: 12,
  },
  cell: {
    "@media (max-width: 599px)": {
      fontSize: 14,
    },
  },
  printCell: {
    fontSize: 11,
    padding: "6px 12px 6px 8px",
  },
  columns: {
    "@media (max-width: 599px)": {
      fontSize: 14,
    },
  },
  printColumns: {
    fontSize: 12,
  },
  colorPrimary: {
    backgroundColor: "#dae3f3",
  },
});
function BasicDataTable(props) {
  const {
    columns = [],
    data = [],
    withIndex = false,
    withPagination,
    rowsPerPageOptions,
    rowsPerPage,
    page = 1,
    count = 0,
    onChangePage,
    onChangeRowsPerPage,
    printable = false,
    headCellColor = "default",
    bodyCellColor = "default",
    rowStyle,
    customPagination,
    ...rest
  } = props;
  const classes = useStyles();

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(data); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getValues = (val, col, dataIndex, colIndex) => {
    const key = col.name;

    if (col.options && col.options.customBodyRender) {
      return col.options.customBodyRender(val[key], val, dataIndex, colIndex);
    }

    let value = val;

    const keys = col.name.split(".");

    keys.forEach((newKey) => {
      value =
        value[newKey] !== undefined && value[newKey] !== null
          ? value[newKey]
          : "";
    });

    return value;
  };

  const paginationOptions = {
    rowsPerPageOptions: rowsPerPageOptions || [15, 25, 50, 100],
    count,
    rowsPerPage: rowsPerPage || 15,
    page,
    onChangePage: (e, newPage) => onChangePage && onChangePage(newPage),
    onChangeRowsPerPage: (e) =>
      onChangeRowsPerPage && onChangeRowsPerPage(e.target.value),
  };

  const getClassName = (val, col, dataIndex, colIndex) => {
    const key = col.name;

    if (typeof col.options.className === "function") {
      return col.options.className(val[key], val, dataIndex, colIndex);
    }

    return col.options.className;
  };

  const getBGColor = () => {
    if (headCellColor === "primary") {
      return "#dae3f3";
    }

    return "ButtonHighlight";
  };

  const getBodyCellColor = () => {
    if (bodyCellColor === "primary") {
      return "#dae3f3";
    }

    return "transparent";
  };

  const getHeadBorderStyle = () => {
    if (headCellColor === "primary") {
      return "1px solid #fff";
    }

    return "";
  };

  const getBorderStyle = () => {
    if (bodyCellColor === "primary") {
      return "1px solid #fff";
    }

    return "";
  };

  const getRowStyles = (value) => {
    let style = {};

    if (rowStyle) {
      style = rowStyle(value);
    }

    return { style };
  };

  const getHeadClassName = (col) => {
    if (col.options && col.options.className) {
      return col.options.className;
    }

    return "";
  };

  const renderData = () => {
    return (
      <Fragment>
        <Table size="small" {...rest}>
          <TableHead>
            <TableRow>
              {withIndex && (
                <TableCell
                  className={
                    printable ? classes.indexPrintColumn : classes.indexColumn
                  }
                  style={{
                    backgroundColor: getBGColor(),
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    border: getHeadBorderStyle(),
                  }}
                >
                  #
                </TableCell>
              )}
              {columns.map((col, i) => (
                <TableCell
                  className={
                    printable
                      ? classes.printColumns
                      : `${classes.columns} ${getHeadClassName(col)}`
                  }
                  style={{
                    backgroundColor: getBGColor(),
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    border: getHeadBorderStyle(),
                  }}
                  key={i}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((value, dataIndex) => (
              <TableRow key={dataIndex} {...getRowStyles(value)}>
                {withIndex && (
                  <TableCell
                    className={
                      printable ? classes.indexPrintColumn : classes.indexColumn
                    }
                    style={{
                      whiteSpace: "nowrap",
                      backgroundColor: getBodyCellColor(),
                      border: getBorderStyle(),
                    }}
                  >
                    {dataIndex + 1}
                  </TableCell>
                )}
                {columns.map((col, colIndex) => (
                  <TableCell
                    className={printable ? classes.printCell : classes.cell}
                    key={colIndex}
                    style={{
                      whiteSpace: "nowrap",
                      backgroundColor: getBodyCellColor(),
                      border: getBorderStyle(),
                      ...((col.options && col.options.styles) || {}),
                    }}
                    {...((col.options &&
                      col.options.className && {
                        className: getClassName(
                          value,
                          col,
                          dataIndex,
                          colIndex
                        ),
                      }) ||
                      {})}
                  >
                    {getValues(value, col, dataIndex, colIndex)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {data.length === 0 && (
          <Typography align="center">
            Sorry, no matching records found
          </Typography>
        )}
      </Fragment>
    );
  };

  return (
    <Paper className={classes.container} component="section">
      {printable ? (
        renderData()
      ) : (
        <TableContainer>{renderData()}</TableContainer>
      )}

      {!customPagination && withPagination && (
        <TablePagination component="div" {...paginationOptions} />
      )}
      {customPagination && <CustomPagination {...paginationOptions} />}
    </Paper>
  );
}

BasicDataTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  withIndex: PropTypes.bool,
  withPagination: PropTypes.bool,
  rowsPerPageOptions: PropTypes.array,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  count: PropTypes.number,
  onChangePage: PropTypes.func,
  printable: PropTypes.bool,
  onChangeRowsPerPage: PropTypes.func,
  headCellColor: PropTypes.oneOf(["default", "primary", "secondary"]),
  bodyCellColor: PropTypes.oneOf(["default", "primary", "secondary"]),
};

export default BasicDataTable;
