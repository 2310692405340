import axios from "axios";
import { handleResponseError } from "../../../utils/helpers";
import Http from "../../../utils/Http";
import { showNofications } from "../../layouts/store/actionCreators";
import * as actions from "./store/actionCreators";

const CancelToken = axios.CancelToken;
let cancel;
export const getAdmissionSubjects = (id) => {
  return Http.get(`/api/enrollments/enrollmentSubjects/${id}`);
};

export const getOfferSubjects = (params = {}) => {
  return Http.get("/api/subjectOffers", { params: { limit: 25, ...params } });
};

export const getEnrollmentSubjects = (id) => {
  return Http.get(`/api/enrollments/enrollmentSubjects/${id}`);
};

export const getOptionsLists = () => {
  return Http.get("/api/optionLists");
};

export const updateAdmission = (id, params) => {
  return Http.post(`/api/enrollments/${id}`, params);
};

export const shiftCourse = (id, params) => {
  return Http.delete(`/api/enrollments/shift-course/${id}`, { params });
};

export const deleteAdmission = (id) => {
  return Http.delete(`/api/enrollments/admission/${id}`);
};

export const getCourses = () => {
  return Http.get("/api/courseOptions");
};

export const resendConfirmationMail = (id) => {
  return Http.post(`/api/enrollments/confirmation-mail/send/${id}`);
};

export const getAccount = (id) => {
  return Http.get(`/api/enrollments/enrollAccount/${id}`);
};

export const getAdmissions = (params = {}) => {
  if (cancel) cancel();

  return (dispatch) => {
    dispatch(actions.requestFetchPendingAdmissions());
    return Http.get("/api/enrollments", {
      params,
      cancelToken: new CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        if (res.data) {
          dispatch(actions.fetchPendingAdmissions(res.data || {}));
          return res.data.data;
        }
      })
      .catch((err) => {
        if (!axios.isCancel) {
          dispatch(showNofications(handleResponseError(err), "error"));
          dispatch(actions.failFetchPendingAdmissions(err));
        }
      });
  };
};

export const acceptAdmission = (id, params) => {
  return (dispatch) => {
    dispatch(actions.requestEnrollPendingAdmissions());
    return Http.post(`/api/enrollments/acceptAdmissions/${id}`, params)
      .then((res) => {
        if (res.data) {
          dispatch(
            showNofications(
              `Admission request by ${res.data.student.full_name} has been accepted.`
            )
          );
          dispatch(actions.enrollPendingAdmissions(res.data || {}));
          return true;
        }
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "error"));
        dispatch(actions.failEnrollPendingAdmissions(err));
      });
  };
};

export const rejectAdmission = (id, params) => {
  return (dispatch) => {
    dispatch(actions.requestEnrollPendingAdmissions());
    return Http.post(`/api/enrollments/rejectAdmissions/${id}`, params)
      .then((res) => {
        if (res.data) {
          dispatch(actions.enrollPendingAdmissions(res.data || {}));
          dispatch(
            showNofications(
              `Admission request by ${res.data.student.full_name} has been rejected.`,
              "error"
            )
          );
          return true;
        }
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "error"));
        dispatch(actions.failEnrollPendingAdmissions(err));
      });
  };
};

export const acceptEnrollments = (id, params) => {
  return (dispatch) => {
    dispatch(actions.requestAcceptEnrollments());
    return Http.post(`/api/enrollments/acceptEnrollments/${id}`, params)
      .then((res) => {
        if (res.data) {
          dispatch(actions.acceptEnrollments(res.data.data || {}));
          dispatch(showNofications("Student Successfully Enrolled."));
          return true;
        }
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "error"));
        dispatch(actions.failAcceptEnrollments(err));
      });
  };
};

export const updateEnrollments = (id, params) => {
  return (dispatch) => {
    dispatch(actions.requestUpdateEnrollments());
    return Http.post(`/api/enrollments/updateEnrollments/${id}`, params)
      .then((res) => {
        if (res.data) {
          dispatch(actions.updateEnrollments(res.data.data || {}));
          dispatch(showNofications("Enrollment Successfully Updated."));
          return res.data.data;
        }
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "error"));
        dispatch(actions.failUpdateEnrollments(err));
      });
  };
};

export const updateEnrollmentInfo = (id, params) => {
  return (dispatch) => {
    dispatch(actions.requestUpdateEnrollments());
    return Http.put(`/api/enrollments/update-info/${id}`, params)
      .then((res) => {
        if (res.data) {
          dispatch(actions.updateEnrollments(res.data.data || {}));
          dispatch(showNofications("Enrollment Successfully Updated."));
          return res.data.data;
        }
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "error"));
        dispatch(actions.failUpdateEnrollments(err));
      });
  };
};

export const withdrawAccount = (id) => {
  return (dispatch) => {
    dispatch(actions.requestWithdrawAccount());
    return Http.post(`/api/enrollments/withdrawn/${id}`)
      .then((res) => {
        if (res.data) {
          dispatch(actions.withdrawAccount(res.data.data || {}));
          dispatch(showNofications("Enrollment Successfully Withdrawn."));
          return res.data.data;
        }
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "error"));
        dispatch(actions.failWithdrawAccount(err));
      });
  };
};

export const removeEnrolledSubject = (id, params) => {
  return (dispatch) => {
    dispatch(actions.requestRemoveEnrolledSubject());
    return Http.post(`/api/enrollments/removeEnrolledSubjects/${id}`, params)
      .then((res) => {
        if (res.data) {
          dispatch(actions.removeEnrolledSubject(res.data.data || {}));
          dispatch(showNofications("Subject Successfully Deleted."));
          return res.data.data;
        }
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "error"));
        dispatch(actions.failRemoveEnrolledSubject(err));
      });
  };
};

export const getEnrolledSubjects = (id) => {
  return Http.get(`/api/enrollments/enrolled-subjects/${id}`);
};
