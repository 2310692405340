export const REQUEST_FETCH_EMPLOYEES = "REQUEST_FETCH_EMPLOYEES";
export const FETCH_EMPLOYEES = "FETCH_EMPLOYEES";
export const FAIL_FETCH_EMPLOYEES = "FAIL_FETCH_EMPLOYEES";

export const REQUEST_CREATE_EMPLOYEE = "REQUEST_CREATE_EMPLOYEE";
export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const FAIL_CREATE_EMPLOYEE = "FAIL_CREATE_EMPLOYEE";

export const REQUEST_UPDATE_EMPLOYEE = "REQUEST_UPDATE_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const FAIL_UPDATE_EMPLOYEE = "FAIL_UPDATE_EMPLOYEE";
