import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import Loader from "../layouts/Loader";
import PropTypes from "prop-types";
import HeaderNotifications from "../modules/layouts/HeaderNotifications";
import Authenticate from "../layouts/Authenticate";

function Public(props) {
  const { component, ...rest } = props;
  const renderLoader = Loader;

  const Component =
    typeof component === "string"
      ? lazy(() => import(`../${component}`))
      : component;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={renderLoader()}>
          <Authenticate>
            <HeaderNotifications />
            <Component {...props} />
          </Authenticate>
        </Suspense>
      )}
    />
  );
}

Public.propTypes = {
  component: PropTypes.any,
};

export default Public;
