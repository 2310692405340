import * as types from "./actionTypes";

const initialState = {
  items: [],
  meta: {},
  fetching: false,
  enrolling: false,
  deleting: false,
  accepting: false,
  removing: false,
  withdrawing: false,
  failed: false,
  errors: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // fetch pending admissions
    case types.REQUEST_FETCH_PENDING_ADMISSIONS:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.FETCH_PENDING_ADMISSIONS:
      return {
        ...state,
        items: payload.data,
        meta: payload.meta,
        fetching: false,
        failed: false,
      };

    case types.FAIL_FETCH_PENDING_ADMISSIONS:
      return {
        ...state,
        fetching: false,
        failed: true,
        errors: payload,
      };

    // enroll pending admissions
    case types.REQUEST_ENROLL_PENDING_ADMISSIONS:
      return {
        ...state,
        enrolling: true,
        failed: false,
      };

    case types.ENROLL_PENDING_ADMISSIONS:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== payload.id),
        enrolling: false,
        failed: false,
      };

    case types.FAIL_ENROLL_PENDING_ADMISSIONS:
      return {
        ...state,
        enrolling: false,
        failed: true,
        errors: payload,
      };

    // reject pending admissions
    case types.REQUEST_REJECT_PENDING_ADMISSIONS:
      return {
        ...state,
        rejecting: true,
        failed: false,
      };

    case types.REJECT_PENDING_ADMISSIONS:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== payload.id),
        rejecting: false,
        failed: false,
      };

    case types.FAIL_REJECT_PENDING_ADMISSIONS:
      return {
        ...state,
        rejecting: false,
        failed: true,
        errors: payload,
      };

    // accept enrollment
    case types.REQUEST_ACCEPT_ENROLEMENT:
      return {
        ...state,
        accepting: true,
        failed: false,
      };

    case types.ACCEPT_ENROLEMENT:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== payload.id),
        accepting: false,
        failed: false,
      };

    case types.FAIL_ACCEPT_ENROLEMENT:
      return {
        ...state,
        accepting: false,
        failed: true,
        errors: payload,
      };

    // update enrollment
    case types.REQUEST_UPDATE_ENROLEMENT:
      return {
        ...state,
        updating: true,
        failed: false,
      };

    case types.UPDATE_ENROLEMENT:
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === payload.id ? payload : item
        ),
        updating: false,
        failed: false,
      };

    case types.DELETE_ENROLEMENT:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== payload.id),
        deleting: false,
        failed: false,
      };

    case types.FAIL_UPDATE_ENROLEMENT:
      return {
        ...state,
        updating: false,
        failed: true,
        errors: payload,
      };

    // remove enrolled subject
    case types.REQUEST_REMOVE_ENROLLED_SUBJECT:
      return {
        ...state,
        removing: true,
        failed: false,
      };

    case types.REMOVE_ENROLLED_SUBJECT:
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === payload.id ? payload : item
        ),
        removing: false,
        failed: false,
      };

    case types.FAIL_REMOVE_ENROLLED_SUBJECT:
      return {
        ...state,
        removing: false,
        failed: true,
        errors: payload,
      };

    // remove enrolled subject
    case types.REQUEST_WITHDRAWN_ACCOUNT:
      return {
        ...state,
        withdrawing: true,
        failed: false,
      };

    case types.WITHDRAWN_ACCOUNT:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== payload.id),
        withdrawing: false,
        failed: false,
      };

    case types.FAILED_WITHDRAWN_ACCOUNT:
      return {
        ...state,
        withdrawing: false,
        failed: true,
        errors: payload,
      };

    default:
      return state;
  }
};

export default reducer;
