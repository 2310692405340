import * as types from "./actionTypes";

const initialState = {
  data: {},
  settings: {},
  fetching: false,
  failed: false,
  errors: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // fetch courses
    case types.REQUEST_FETCH_INFORMATIONS:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.FETCH_INFORMATIONS:
      return {
        ...state,
        data: payload,
        fetching: false,
        failed: false,
      };

    case types.FAIL_FETCH_INFORMATIONS:
      return {
        ...state,
        fetching: false,
        failed: true,
        errors: payload,
      };

    default:
      return state;
  }
};

export default reducer;
