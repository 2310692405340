import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FormDialog from "../../../../components/FormDialog";
import { Revalidate } from "../../../../utils/helpers";
import palletes from "../../../../assets/styles/palletes";

import PropTypes from "prop-types";
import FormSelectField from "../../../../components/FormSelectField";
import { Typography } from "@material-ui/core";
import { levelOptions } from "../../../../utils/helpers";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
  title: {
    color: palletes.error,
  },
  button: {
    paddingLeft: 40,
    paddingRight: 40,
    margin: 5,
  },
  buttonReject: {
    backgroundColor: "#e3342f",
    "&:hover": {
      backgroundColor: "#e3342f",
    },
  },
});

const validator = Revalidate({
  course_id: "required",
  level: "required",
});

function ShiftCourse({
  open,
  onClose,
  courses = [],
  handleConfirm,
  loading,
  selectedStudent = {},
}) {
  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setFormValues({
        values: {
          course_id: "",
          level: "",
        },
        errors: validator.errors,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [formValues, setFormValues] = useState({
    values: {
      course_id: "",
      level: "",
    },
    errors: validator.errors,
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    const { errors } = validator;

    errors.remove(name);

    setFormValues((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        [name]: value,
      },
    }));

    validator.validate(name, value).then(() => {
      setFormValues((prev) => ({
        ...prev,
        errors,
      }));
    });
  };

  const getCourses = () => {
    if (selectedStudent && selectedStudent.course) {
      return courses.filter(
        (course) => course.id !== selectedStudent.course.id
      );
    }

    return courses;
  };

  const handleSubmit = () => {
    validator.validateAll(formValues.values).then((success) => {
      if (success) {
        handleConfirm(formValues.values);
      } else {
        setFormValues((prev) => ({
          ...prev,
          errors: validator.errors,
        }));
      }
    });
  };

  return (
    <FormDialog open={open} onClose={onClose}>
      <DialogTitle className={classes.title}>Shift Course</DialogTitle>
      <DialogContent>
        <FormSelectField
          label="Select Course"
          name="course_id"
          options={getCourses()}
          keyValuePair="id,code"
          onChange={handleChange}
          value={formValues.values.course_id}
          errors={formValues.errors}
        />
        <FormSelectField
          label="Select Level"
          name="level"
          options={levelOptions()}
          keyValuePair="id,label"
          onChange={handleChange}
          value={formValues.values.level}
          errors={formValues.errors}
        />
        <Typography color="error">
          Current subject enrolled will be removed.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          className={`${classes.button} buttonSuccess`}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
        <Button
          className={`${classes.button} ${classes.buttonReject}`}
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </FormDialog>
  );
}

ShiftCourse.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  courses: PropTypes.array,
  handleConfirm: PropTypes.func,
  loading: PropTypes.bool,
  selectedStudent: PropTypes.object,
};

export default ShiftCourse;
