import { handleResponseError } from "../../../utils/helpers";
import Http from "../../../utils/Http";
import { showNofications } from "../../layouts/store/actionCreators";
import * as actions from "./store/actionCreators";

export const getCourses = () => {
  return Http.get("/api/courses");
};

export const getAllCurriculum = (params = {}) => {
  return (dispatch) => {
    dispatch(actions.requestFetchCurriculum());
    return Http.get("/api/curriculum", { params })
      .then((res) => {
        if (res.data) {
          dispatch(actions.fetchCurriculum(res.data));
        }
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "error"));
        dispatch(actions.failFetchCurriculum(err));
      });
  };
};

export const createCurriculum = (params = {}) => {
  return (dispatch) => {
    dispatch(actions.requestCreateCurriculum());
    return Http.post("/api/curriculum", params)
      .then((res) => {
        if (res.data) {
          dispatch(showNofications("Curriculum Successfully Created"));
          dispatch(actions.createCurriculum(res.data));

          return res.data.data;
        }
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "error"));
        dispatch(actions.failCreateCurriculum(err));
      });
  };
};

export const updateCurriculum = (params = {}, id) => {
  return (dispatch) => {
    dispatch(actions.requestUpdateCurriculum());
    return Http.put(`/api/curriculum/${id}`, params)
      .then((res) => {
        if (res.data) {
          dispatch(showNofications("Curriculum Successfully Updated"));
          dispatch(actions.updateCurriculum(res.data.data));
          return true;
        }
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "error"));
        dispatch(actions.failUpdateCurriculum(err));
      });
  };
};
