export const REQUEST_FETCH_SUBJECT_CATEGORIES =
  "REQUEST_FETCH_SUBJECT_CATEGORIES";
export const FETCH_SUBJECT_CATEGORIES = "FETCH_SUBJECT_CATEGORIES";
export const FAIL_FETCH_SUBJECT_CATEGORIES = "FAIL_FETCH_SUBJECT_CATEGORIES";

export const REQUEST_CREATE_SUBJECT_CATEGORY =
  "REQUEST_CREATE_SUBJECT_CATEGORY";
export const CREATE_SUBJECT_CATEGORY = "CREATE_SUBJECT_CATEGORY";
export const FAIL_CREATE_SUBJECT_CATEGORY = "FAIL_CREATE_SUBJECT_CATEGORY";

export const REQUEST_UPDATE_SUBJECT_CATEGORY =
  "REQUEST_UPDATE_SUBJECT_CATEGORY";
export const UPDATE_SUBJECT_CATEGORY = "UPDATE_SUBJECT_CATEGORY";
export const FAIL_UPDATE_SUBJECT_CATEGORY = "FAIL_UPDATE_SUBJECT_CATEGORY";

export const REQUEST_DELETE_SUBJECT_CATEGORY =
  "REQUEST_DELETE_SUBJECT_CATEGORY";
export const DELETE_SUBJECT_CATEGORY = "DELETE_SUBJECT_CATEGORY";
export const FAIL_DELETE_SUBJECT_CATEGORY = "FAIL_DELETE_SUBJECT_CATEGORY";
