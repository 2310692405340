import * as types from "./actionTypes";

// fetching courses
export function requestFetchInformations() {
  return {
    type: types.REQUEST_FETCH_INFORMATIONS,
  };
}

export function fetchInformations(data) {
  return {
    type: types.FETCH_INFORMATIONS,
    payload: data,
  };
}

export function failFetchInformations(data) {
  return {
    type: types.FAIL_FETCH_INFORMATIONS,
    payload: data,
  };
}
