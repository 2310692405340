import React from "react";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";

import "./assets/css/material-dashboard-react.css?v=1.0.0";
import "font-awesome/css/font-awesome.min.css";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import { createRoot } from "react-dom/client";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>
);
