import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BasicDataTable from "../../../components/BasicDataTable";
import * as loadService from "../subjectLoadService";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import FormSelectField from "../../../components/FormSelectField";
import { schoolYearOptions } from "../../../utils/helpers";
import { semesterOptions } from "../../../utils/helpers";
import { useSelector } from "react-redux";

const filterKey = "instructor-grades-filter";
function Grades({ history, match }) {
  const settings = useSelector((state) => state.settings.data);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    school_year: settings.school_year,
    semester: settings.semester,
  });

  useEffect(() => {
    const localFilter = JSON.parse(localStorage.getItem(filterKey));

    if (localFilter) {
      setFilters(localFilter);
    }

    fetchingData(localFilter || filters); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: "id",
      label: "Action",
      options: {
        customBodyRender: (value) => (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => handleNavigate(value)}
          >
            Roster
          </Button>
        ),
      },
    },
    {
      name: "subject_code",
      label: "Code",
    },
    {
      name: "subject_info",
      label: "Description",
      options: {
        customBodyRender: (value) => {
          return (
            (value && value.prospectus && value.prospectus.descriptive_title) ||
            ""
          );
        },
      },
    },
    {
      name: "subject_info",
      label: "Section",
      options: {
        customBodyRender: (value) => {
          return (value && value.section) || "";
        },
      },
    },
    {
      name: "subject_info",
      label: "Units",
      options: {
        customBodyRender: (value) => {
          return (value && value.prospectus && value.prospectus.units) || "";
        },
      },
    },
  ];

  const handleNavigate = (id) => {
    history.push(`${match.path}/roster/${id}`);
  };

  const fetchingData = (params = {}) => {
    localStorage.setItem(filterKey, JSON.stringify(params));
    setLoading(true);
    loadService
      .getSubjectLoads(params)
      .then((res) => {
        if (res.data.data) {
          setItems(res.data.data || []);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleApplyFilter = () => {
    fetchingData(filters);
  };

  return (
    <Card>
      <CardContent>
        <Box paddingBottom={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <FormSelectField
                label="School Year"
                name="school_year"
                options={schoolYearOptions()}
                value={filters.school_year}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormSelectField
                label="Semester"
                name="semester"
                keyValuePair="id,label"
                options={semesterOptions()}
                value={filters.semester}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={4} container alignItems="center">
              <Button
                color="primary"
                variant="contained"
                onClick={handleApplyFilter}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
        </Box>
        {loading ? (
          <Grid container justify="center">
            <CircularProgress />
          </Grid>
        ) : (
          <BasicDataTable withIndex columns={columns} data={items} />
        )}
      </CardContent>
    </Card>
  );
}

Grades.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export default Grades;
