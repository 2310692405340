import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BasicDataTable from "../../../components/BasicDataTable";
import * as service from "../studentService";
import palletes from "../../../assets/styles/palletes";
import PrintIcon from "@material-ui/icons/Print";
import { getLevel } from "../../../utils/helpers";
import { Fragment } from "react";
import { useLocation } from "react-router";

const useStyles = makeStyles({
  warning: {
    backgroundColor: palletes.error,
    color: "#fff",
    padding: "12px 4px",
  },
  print: {
    backgroundColor: palletes.error,
    color: "#fff",
    "&:hover": {
      backgroundColor: palletes.error,
    },
  },
});
function Subjects() {
  const classes = useStyles();
  const location = useLocation();

  const [account, setAccount] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    service
      .getLoads()
      .then((res) => {
        if (res.data.data) {
          setAccount(res.data.data || {});
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      name: "subjectOffer.prospectus.code",
      label: "Code",
    },
    {
      name: "subjectOffer.prospectus.descriptive_title",
      label: "Description",
    },
    {
      name: "subjectOffer.section",
      label: "Section",
    },
    {
      name: "subjectOffer",
      label: "Instructor",
      options: {
        customBodyRender: (value) => {
          if (value.employee) {
            return value.employee.partial_name;
          }
          return "";
        },
      },
    },
    {
      name: "subjectOffer",
      label: "Schedule",
      options: {
        customBodyRender: (value) => {
          return (value && value.subject_schedule) || "";
        },
      },
    },
    {
      name: "subjectOffer.prospectus.type",
      label: "Type",
    },
    {
      name: "subjectOffer.prospectus.units",
      label: "Units",
    },
  ];

  const getTotalUnits = () => {
    let units = 0;
    if (account.loads) {
      account.loads.forEach((item) => {
        if (item.subjectOffer && item.subjectOffer.prospectus) {
          units += parseInt(item.subjectOffer.prospectus.units || 0);
        }
      });
    }

    return units;
  };

  const handlePrint = () => {
    const win = window.open(
      `${location.pathname.replace("/admin", "")}/print/${account.id}`,
      "_blank"
    );
    win.focus();
  };

  return (
    <Card>
      {loading ? (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      ) : (
        <Fragment>
          <CardContent>
            <Grid container justify="space-between">
              <Grid item>
                <Typography>
                  {account.course && account.course.code}:{" "}
                  {getLevel(account.level)}
                </Typography>
              </Grid>
              <Grid item>
                <Button className={classes.print} onClick={handlePrint}>
                  <PrintIcon /> Print
                </Button>
              </Grid>
            </Grid>
            <BasicDataTable withIndex data={account.loads} columns={columns} />
          </CardContent>
          <CardContent>
            <Grid container justify="flex-end">
              <Grid item>
                <Typography>Total Units: {getTotalUnits()}</Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Typography align="center" className={classes.warning}>
              {`This is a system generated report. If you need a signed version of
              this report. Please bring a printed out copy of this report to the
              registrar's office for signing.`}
            </Typography>
          </CardContent>
        </Fragment>
      )}
    </Card>
  );
}

export default Subjects;
