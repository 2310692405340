import React, { useEffect, useState } from "react";
import FormField from "../../../../components/FormField";
import FormSelectField from "../../../../components/FormSelectField";
import FormDialog from "../../../../components/FormDialog";
import { Revalidate } from "../../../../utils/helpers";
import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import PropTypes from "prop-types";

const validator = Revalidate({
  first_name: "required",
  last_name: "required",
  middle_name: "",
  personal_email: "required|email",
  contact_number: "required|integer|max:11|min:11",
  role: "required",
});

function AddForm(props) {
  const { open, onClose, allRoles = [], loading, handleCreate } = props;

  useEffect(() => {
    setFormValues((prev) => ({
      ...prev,
      values: {
        first_name: "",
        last_name: "",
        middle_name: "",
        personal_email: "",
        contact_number: "",
        role: "",
      },
    })); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [roles, setRoles] = useState([]);
  const [formValues, setFormValues] = useState({
    values: {
      first_name: "",
      last_name: "",
      middle_name: "",
      personal_email: "",
      contact_number: "",
      role: "",
    },
    errors: validator.errors,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const { errors } = validator;

    errors.remove(name);

    setFormValues((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        [name]: value,
      },
    }));

    validator
      .validate(name, value)
      .then(() =>
        setFormValues((prev) => ({ ...prev, errors: validator.errors }))
      );
  };

  const handleCheckRoles = (name) => {
    if (roles.includes(name)) {
      setRoles((prev) => prev.filter((rl) => rl !== name));
    } else {
      setRoles((prev) => [...prev, name]);
    }
  };

  const handleSubmit = () => {
    validator.validateAll(formValues.values).then((success) => {
      if (success) {
        handleCreate({
          ...formValues.values,
          roles,
        });
      } else {
        setFormValues((prev) => ({
          ...prev,
          errors: validator.errors,
        }));
      }
    });
  };

  return (
    <FormDialog open={open} onClose={onClose}>
      <DialogTitle>New User</DialogTitle>
      <DialogContent>
        <Typography color="error">
          Student User cannot be added on this form!
        </Typography>
        <Divider />
        <FormField
          name="first_name"
          label="First Name"
          value={formValues.values.first_name}
          onChange={handleChange}
          errors={formValues.errors}
        />
        <FormField
          name="last_name"
          label="Last Name"
          value={formValues.values.last_name}
          onChange={handleChange}
          errors={formValues.errors}
        />
        <FormField
          name="middle_name"
          label="Middle Name"
          onChange={handleChange}
          value={formValues.values.middle_name}
          errors={formValues.errors}
        />
        <FormField
          name="personal_email"
          label="Personal Email"
          type="email"
          value={formValues.values.personal_email}
          onChange={handleChange}
          errors={formValues.errors}
        />

        <FormSelectField
          name="role"
          label="Role"
          options={allRoles.filter((role) => role !== "Student")}
          value={formValues.values.role}
          onChange={handleChange}
          errors={formValues.errors}
        />

        <FormField
          name="contact_number"
          label="Contact Number"
          value={formValues.values.contact_number}
          onChange={handleChange}
          errors={formValues.errors}
        />

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>Roles</Typography>
          </Grid>
          {allRoles
            .filter((role) => role !== "Student")
            .map((role, index) => (
              <Grid item xs={4} key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={roles.includes(role)}
                      onChange={() => handleCheckRoles(role)}
                      name={role}
                      color="primary"
                    />
                  }
                  label={role}
                />
              </Grid>
            ))}
        </Grid>

        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            disabled={loading}
          >
            {loading ? <CircularProgress /> : "Submit"}
          </Button>
        </div>
      </DialogContent>
    </FormDialog>
  );
}

AddForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  allRoles: PropTypes.array,
  loading: PropTypes.bool,
  handleCreate: PropTypes.func,
};

export default AddForm;
