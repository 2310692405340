import * as types from "./actionTypes";

const initialState = {
  items: [],
  meta: {},
  fetching: false,
  creating: false,
  updating: false,
  updatingOffer: false,
  failed: false,
  errors: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // fetch employees
    case types.REQUEST_FETCH_SUBJECTOFFERS:
      return {
        ...state,
        fetching: true,
        failed: false,
      };

    case types.FETCH_SUBJECTOFFERS:
      return {
        ...state,
        items: payload.data,
        meta: payload.meta,
        fetching: false,
        failed: false,
      };

    case types.FAIL_FETCH_SUBJECTOFFERS:
      return {
        ...state,
        fetching: false,
        failed: true,
        errors: payload,
      };

    // update offer
    case types.REQUEST_UPDATE_OFFER:
      return {
        ...state,
        updatingOffer: true,
        failed: false,
      };

    case types.UPDATE_OFFER:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === parseInt(payload.id)) {
            return payload;
          }

          return item;
        }),
        updatingOffer: false,
        failed: false,
      };

    case types.FAIL_UPDATE_OFFER:
      return {
        ...state,
        updatingOffer: false,
        failed: true,
        errors: payload,
      };

    // update subject offer
    case types.REQUEST_UPDATE_SUBJECTOFFER:
      return {
        ...state,
        updating: true,
        failed: false,
      };

    case types.UPDATE_SUBJECTOFFER:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === parseInt(payload.id)) {
            return payload;
          }

          return item;
        }),
        updating: false,
        failed: false,
      };

    case types.FAIL_UPDATE_SUBJECTOFFER:
      return {
        ...state,
        updating: false,
        failed: true,
        errors: payload,
      };

    default:
      return state;
  }
};

export default reducer;
