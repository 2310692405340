import React from "react";
import { memo } from "react";
import ControlPointIcon from "@material-ui/icons/ControlPoint"; // new request
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned"; // for enrollment
import HighlightOffIcon from "@material-ui/icons/HighlightOff"; // rejected
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"; // enrolled
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    padding: "4px 12px",
    alignItems: "center",
    borderBottom: "1px solid #ccc",

    "&:hover": {
      backgroundColor: "#4e73df",
      color: "#fff",
      cursor: "pointer",
    },
  },
  message: {
    fontSize: "12px",
  },
  title: {
    fontSize: "14px",
  },
  date: {
    fontSize: "11px",
  },
  messageBox: {
    paddingLeft: 6,
  },
});
function NotificationTemplate({ handleClick, notification }) {
  const classes = useStyles();

  const getIcon = (name) => {
    if (name === "request") {
      return <ControlPointIcon />;
    }

    if (name === "for_enrollment") {
      return <AssignmentReturnedIcon />;
    }

    if (name === "rejected") {
      return <HighlightOffIcon />;
    }

    if (name === "enrolled") {
      return <AssignmentIndIcon />;
    }
  };

  const getMessage = () => {
    const message = notification.data.message || "";
    if (message.length > 74) {
      return `${message.substr(0, 74)}...`;
    }

    return message;
  };

  return (
    <div className={classes.root} onClick={handleClick}>
      {getIcon(notification.data.type)}
      <div className={classes.messageBox}>
        <Typography className={classes.message}>{getMessage()}</Typography>
        <Typography className={classes.date}>
          {notification.created_at || ""}
        </Typography>
      </div>
    </div>
  );
}

export default memo(NotificationTemplate);
