import * as types from "./actionTypes";

export function showNofications(message, type = "success") {
  return {
    type: types.SHOW_NOTIFICATIONS,
    payload: {
      message,
      type,
    },
  };
}

export function hideNotifications() {
  return {
    type: types.HIDE_NOTIFICATIONS,
  };
}
