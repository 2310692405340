import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route } from "react-router-dom";
import Loader from "../layouts/Loader";
import { checkPermissions, isAuth } from "../utils/helpers";
import PropTypes from "prop-types";
import Authenticate from "../layouts/Authenticate";
import { useSelector } from "react-redux";
import Forbidden from "../layouts/Forbidden";

function Private(props) {
  const { component, ...rest } = props;
  const user = useSelector((state) => state.user);

  const ComponentUI =
    typeof component === "string"
      ? lazy(() => import(`../${component}`))
      : component;

  const renderLoader = Loader;

  if (!isAuth()) {
    return <Route {...rest} render={() => <Redirect to="/" />} />;
  }

  const isAccessAllowed = () =>
    checkPermissions(props.path.replace("/admin", ""), user.data);

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={renderLoader()}>
          <Authenticate>
            <Helmet>
              <title>MLGCL - {rest.name}</title>
            </Helmet>
            {isAccessAllowed() ? (
              <ComponentUI {...props} />
            ) : !user.data.roles ? (
              ""
            ) : (
              <Forbidden />
            )}
          </Authenticate>
        </Suspense>
      )}
    />
  );
}

Private.propTypes = {
  component: PropTypes.any,
};

export default Private;
