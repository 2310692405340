import * as types from "./actionTypes";

const initialState = {
  items: [],
  meta: {},
  fetching: false,
  errors: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.REQUEST_FETCH_STUDENT_TUITIONS:
      return {
        ...state,
        fetching: true,
        errors: {},
      };

    case types.FETCH_STUDENT_TUITIONS:
      return {
        ...state,
        fetching: false,
        items: payload.data,
        meta: payload.meta,
        errors: {},
      };

    case types.FAIL_FETCH_STUDENT_TUITIONS:
      return {
        ...state,
        fetching: false,
        errors: payload,
      };

    case types.UPDATE_STUDENT_TUITION:
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };

    case types.DELETE_STUDENT_TUITION:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== payload.id),
      };
    default:
      return state;
  }
};

export default reducer;
