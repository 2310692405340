import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const useHasPermission = (permissions) => {
  const user = useSelector((state) => state.user.data);
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    if (user.role_permissions && user.direct_permissions) {
      checkPermission();
    }
  }, [user.role_permissions && user.role_permissions.names]); // eslint-disable-line

  const checkPermission = () => {
    if (typeof permissions === "string") {
      setHasPermission(
        user.is_admin ||
          user.role_permissions.names.includes(permissions) ||
          user.direct_permissions.includes(permissions)
      );
    }
  };

  return hasPermission;
};

export default useHasPermission;
