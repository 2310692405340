import * as types from "./actionTypes";

// fetching curriculum
export function requestFetchCurriculum() {
  return {
    type: types.REQUEST_FETCH_CURRICULUM,
  };
}

export function fetchCurriculum(data) {
  return {
    type: types.FETCH_CURRICULUM,
    payload: data,
  };
}

export function failFetchCurriculum(data) {
  return {
    type: types.FAIL_FETCH_CURRICULUM,
    payload: data,
  };
}

// creating curriculum
export function requestCreateCurriculum() {
  return {
    type: types.REQUEST_CREATE_CURRICULUM,
  };
}

export function createCurriculum(data) {
  return {
    type: types.CREATE_CURRICULUM,
    payload: data,
  };
}

export function failCreateCurriculum(data) {
  return {
    type: types.FAIL_CREATE_CURRICULUM,
    payload: data,
  };
}

// update curriculum
export function requestUpdateCurriculum() {
  return {
    type: types.REQUEST_UPDATE_CURRICULUM,
  };
}

export function updateCurriculum(data) {
  return {
    type: types.UPDATE_CURRICULUM,
    payload: data,
  };
}

export function failUpdateCurriculum(data) {
  return {
    type: types.FAIL_UPDATE_CURRICULUM,
    payload: data,
  };
}
