import * as types from "./actionTypes";

const initialState = {
  type: "success",
  message: "",
  show: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SHOW_NOTIFICATIONS:
      return {
        ...state,
        type: payload.type,
        message: payload.message,
        show: true,
      };

    case types.HIDE_NOTIFICATIONS:
      return {
        ...state,
        message: "",
        show: false,
      };

    default:
      return state;
  }
};

export default reducer;
