import { TextField } from "@material-ui/core";
import React from "react";

import PropTypes from "prop-types";

function FormSelectField(props) {
  const {
    errors,
    options = [],
    customError,
    customPlaceholder,
    keyValuePair = "",
    noOptions = false,
    ...rest
  } = props;

  let valueKey = "";
  let labelKey = "";
  let isValuePair = false;

  if (keyValuePair && keyValuePair.includes(",")) {
    isValuePair = true;
    const keys = keyValuePair.split(",");
    valueKey = keys[0]; // option value
    labelKey = keys[1]; // option label
  }

  let error = false;
  let helperText = "";

  // handle customError
  if (customError) {
    error = customError.error || false;
    helperText = customError.message || "";
  }

  if (errors) {
    error = (errors && errors.has(props.name)) || false;
    helperText = (errors && errors.first(props.name)) || "";
  }

  const newProps = {
    variant: "outlined",
    select: true,
    margin: "dense",
    fullWidth: true,
    error,
    helperText,
    ...rest,
    value: rest.value || "",
  };

  return (
    <TextField
      InputLabelProps={{ shrink: true }}
      SelectProps={{ native: true }}
      {...newProps}
    >
      {!noOptions && (
        <option value="">
          {customPlaceholder ? customPlaceholder : "Select Option"}
        </option>
      )}
      {!isValuePair &&
        options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}

      {isValuePair &&
        options.map((option, i) => (
          <option key={i} value={option[valueKey]}>
            {option[labelKey]}
          </option>
        ))}
    </TextField>
  );
}

FormSelectField.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string,
  options: PropTypes.array,
  keyValuePair: PropTypes.string,
  customPlaceholder: PropTypes.string,
  noOptions: PropTypes.bool,
  customError: PropTypes.any,
};

export default FormSelectField;
