import { DialogContent } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import FormDialog from "../../../../components/FormDialog";
import React from "react";
import PropTypes from "prop-types";

function DeleteUser({ handleConfirm, loading, ...rest }) {
  return (
    <FormDialog {...rest}>
      <DialogTitle>Delete User</DialogTitle>
      <DialogContent>
        <Typography color="error">
          Are you sure you want to delete this user? All data related to this
          user will also be deleted.
        </Typography>
        <Typography color="error">Cannot be restored.</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          className="buttonSuccess"
          disabled={loading}
          onClick={handleConfirm}
        >
          {loading ? <CircularProgress /> : "Confirm"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="buttonReject"
          disabled={loading}
          onClick={() => rest.onClose()}
        >
          Close
        </Button>
      </DialogActions>
    </FormDialog>
  );
}

DeleteUser.propTypes = {
  loading: PropTypes.bool,
  handleConfirm: PropTypes.func,
};

export default DeleteUser;
