import { systemRoles } from "../utils/helpers";

const publicRoute = [
  {
    path: "/",
    path_name: "Home",
    component: "modules/home",
  },
  {
    path: "/first-login",
    path_name: "First Login",
    component: "modules/home/FirstLogin",
  },
  {
    path: "/online-admission",
    path_name: "Admission",
    component: "modules/admission/pages/Admission",
  },
  {
    path: "/online-admission/:trans_no",
    path_name: "Edit Admission",
    component: "modules/admission/pages/EditAdmission",
  },
  {
    path: "/confirm",
    path_name: "Final Enrollment",
    component: "modules/admission/components/OldStudentEnrollmentConfirmation",
  },
  {
    path: "/confirm/print",
    path_name: "Print Final Enrollment",
    component: "modules/admission/pages/PrintOldStudentAdmission",
  },
  {
    path: "/complete-admission",
    path_name: "Final Enrollment",
    component: "modules/admission/components/FinalizeEnrollment",
  },
  {
    path: "/complete-admission/print",
    path_name: "Final Enrollment",
    component: "modules/admission/components/PrintAdmission",
  },
  {
    path: "/complete-admission/admin/print",
    path_name: "Final Enrollment",
    component: "modules/admin/admission/components/PrintAdmission",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    path: "/student/subjects/print/:account_id",
    name: "Print COR",
    component: "modules/student/pages/PrintCOR",
    allowedRoles: [systemRoles.student],
  },
  {
    path: "/student/grades/print/:account_id",
    name: "Print Grades",
    component: "modules/student/pages/PrintGrades",
    allowedRoles: [systemRoles.student],
  },
  {
    path: "/enrollments/enrolled/:account_id/print",
    name: "Print COR",
    component: "modules/admin/enrollment/pages/PrintCOR",
    allowedRoles: [systemRoles.registrar, systemRoles.administrator],
  },
  {
    path: "/payments/:account_id/print",
    name: "Print COR",
    component: "modules/admin/cashier/pages/PrintPayment",
    allowedRoles: [systemRoles.cashier, systemRoles.administrator],
  },
  {
    name: "Student Grades",
    path: "/students/:id/grades/print",
    component: "modules/admin/studentList/print/StudentGradePrint",
    allowedRoles: [systemRoles.administrator],
  },

  {
    name: "Student Verify",
    path: "/student/verify-status/:id",
    component: "modules/verify-student/pages/VerifyStudent",
  },

  // intructor public routes
  {
    path: "/instructor/subject-loads/roster/:sub_id/print",
    name: "Print Roster",
    component: "modules/instructor/pages/PrintRoster",
    allowedRoles: [systemRoles.full_time, systemRoles.part_time],
  },
  {
    path: "/instructor/grades/roster/:sub_id/print",
    name: "Grades Roster",
    component: "modules/instructor/pages/PrintGrades",
    allowedRoles: ["Full Time Instructor", "Part Time Instructor"],
  },

  // admin public route
  {
    path: "/employees/subjects/:id/roster/:sub_id/print",
    component: "modules/admin/employee/pages/PrintRoster",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.registrar,
      systemRoles.school_admin,
    ],
  },

  {
    path: "/employees/subjects/:id/grades/:sub_id/print",
    component: "modules/admin/employee/pages/PrintGrades",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.registrar,
      systemRoles.school_admin,
    ],
  },

  {
    path: "/subjects/:id/roster/:sub_id/print",
    component: "modules/admin/subject/pages/PrintRoster",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.registrar,
      systemRoles.school_admin,
    ],
  },
  {
    path: "/subjects/:id/grades/:sub_id/print",
    component: "modules/admin/subject/pages/PrintGrades",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.registrar,
      systemRoles.school_admin,
    ],
  },
  {
    path: "/attendance/:id/qr-code",
    component: "modules/qrcode-attendance/pages/QrCodeAttendance",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.guard],
  },
  {
    path: "/attendance/:id/qr-code/print",
    component: "modules/qrcode-attendance/pages/PrintAttendance",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.guard],
  },
  {
    path: "/forgot-password",
    component: "modules/forgot-password/pages/ForgotPassword",
  },
  {
    path: "/reset-password",
    component: "modules/forgot-password/pages/ResetPassword",
  },
];

export default publicRoute;
