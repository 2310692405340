import User from "../modules/admin/user/pages/User";
// core components/views for Admin layout
import Curriculum from "../modules/admin/curriculum/pages/Curriculum";
import Setting from "../modules/admin/setting/pages/Setting";
import Enrolled from "../modules/admin/enrollment/pages/Enrolled";
import Subjects from "../modules/student/pages/Subjects";
import EClearance from "../modules/student/pages/EClearance";
import InstructorEClearance from "../modules/instructor/pages/EClearance";
import Grades from "../modules/student/pages/Grades";
import InstructorGrades from "../modules/instructor/pages/Grades";
import SubjectLoad from "../modules/instructor/pages/SubjectLoad";
import { systemRoles } from "../utils/helpers";
import DuplicateUser from "../modules/admin/user/pages/DuplicateUser";
import PassReset from "../modules/admin/password-reset/pages/PassReset";
// core components/views for RTL layout

export const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: "views/Dashboard/Dashboard.js",
    layout: "/admin",
    allowedRoles: ["*"],
  },
  {
    path: "/announcements",
    name: "Annoucements",
    component: "modules/admin/announcement/pages/Announcement",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.registrar,
      systemRoles.school_admin,
      systemRoles.part_time,
      systemRoles.full_time,
    ],
  },
  {
    path: "/announcements/create",
    name: "Create Annoucements",
    component: "modules/admin/announcement/pages/Create",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.registrar,
      systemRoles.school_admin,
      systemRoles.part_time,
      systemRoles.full_time,
    ],
  },
  {
    path: "/qrcode-scanner",
    name: "QrCode Scanner",
    component: "modules/admin/qr-scanner/pages/Scanner",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.registrar,
      systemRoles.registrar,
    ],
  },
  {
    path: "/attendance",
    name: "Attendance",
    component: "modules/qrcode-attendance/pages/Attendance",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.guard],
  },
  {
    path: "/attendance/:id",
    name: "QrCode Attendance",
    component: "modules/qrcode-attendance/pages/ListAttendance",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.guard],
  },
  {
    path: "/id cards/students",
    name: "Students",
    component: "modules/admin/cards/pages/Student",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator],
  },
  {
    path: "/identification-number",
    name: "Identification Number",
    component: "modules/admin/identification-number/pages/StudentIdManagement",
    layout: "/admin",
  },
  {
    path: "/id cards/employees",
    name: "Faculty & Staff",
    component: "modules/admin/cards/pages/Employee",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator],
  },
  {
    path: "/user",
    name: "User Profile",
    component: "views/UserProfile/UserProfile.js",
    layout: "/admin",
    allowedRoles: ["*"],
  },
  {
    path: "/admission/college",
    name: "College Admission",
    layout: "/admin",
    component: "modules/admin/admission/pages/Admission",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    path: "/admission/high-school",
    name: "High School Admission",
    layout: "/admin",
    component: "modules/admin/admission/pages/Admission",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    path: "/complete-admission",
    name: "Final Enrollment",
    layout: "/admin",
    component: "modules/admin/admission/components/FinalizeEnrollment",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    path: "/confirm",
    name: "Final Enrollment",
    layout: "/admin",
    component:
      "modules/admin/admission/components/OldStudentEnrollmentConfirmation",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    path: "/enrollments/list",
    name: "All Enrollments",
    component: "modules/admin/enrollment/pages/List",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    path: "/enrollments/new-request",
    name: "New Request",
    component: "modules/admin/enrollment/pages/NewRequest",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    path: "/enrollments/rejected",
    name: "Rejected",
    component: "modules/admin/enrollment/pages/Rejected",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    path: "/enrollments/cashier-hold",
    name: "Cashier Hold",
    component: "modules/admin/enrollment/pages/CashierHold",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    path: "/enrollments/for-enrollments",
    name: "For Enrollments",
    component: "modules/admin/enrollment/pages/ForEnrollment",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    path: "/enrollments/enrolled",
    name: "Enrolled",
    component: Enrolled,
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    path: "/users",
    name: "Users",
    component: User,
    layout: "/admin",
    allowedRoles: [systemRoles.administrator],
  },

  {
    name: "Enrollments Statistics",
    path: "/reports/enrollments-statistics",
    component: "modules/admin/reports/pages/EnrollmentReports",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.school_admin,
      systemRoles.registrar,
    ],
  },
  {
    name: "Cashiers Collectibles",
    path: "/reports/cashiers",
    component: "modules/admin/reports/pages/CashierReports",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.school_admin,
      systemRoles.cashier,
    ],
  },
  {
    name: "Faculty & Staff",
    path: "/employees",
    component: "modules/admin/employee/pages/Employee",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.registrar,
      systemRoles.school_admin,
    ],
  },
  {
    name: "Subjects",
    path: "/employees/subjects/:id",
    component: "modules/admin/employee/pages/Subjects",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.registrar,
      systemRoles.school_admin,
    ],
  },
  {
    name: "Subject Roster",
    path: "/employees/subjects/:id/roster/:sub_id",
    component: "modules/admin/employee/pages/SubjectRoster",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.registrar,
      systemRoles.school_admin,
    ],
  },
  {
    name: "Clearance",
    path: "/employees/subjects/:id/clearance/:sub_id",
    component: "modules/admin/employee/pages/FacultyClearance",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator],
  },
  {
    name: "Grades",
    path: "/employees/subjects/:id/grades/:sub_id",
    component: "modules/admin/employee/pages/SubjectGrade",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator],
  },
  {
    name: "Subjects",
    path: "/subjects",
    component: "modules/admin/subject/pages/Subject",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.registrar,
      systemRoles.school_admin,
    ],
  },
  {
    name: "Subject Roster",
    path: "/subjects/:id/roster/:sub_id",
    component: "modules/admin/subject/pages/SubjectRoster",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.registrar,
      systemRoles.school_admin,
    ],
  },
  {
    name: "Subject Clearance",
    path: "/subjects/:id/clearance/:sub_id",
    component: "modules/admin/subject/pages/Clearance",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.registrar,
      systemRoles.school_admin,
    ],
  },
  {
    name: "Subject Grade",
    path: "/subjects/:id/grades/:sub_id",
    component: "modules/admin/subject/pages/GradeRoster",
    layout: "/admin",
    allowedRoles: [
      systemRoles.administrator,
      systemRoles.registrar,
      systemRoles.school_admin,
    ],
  },

  {
    name: "Students",
    path: "/students",
    component: "modules/admin/studentList/pages/StudentList",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    name: "New Student",
    path: "/students/new",
    component: "modules/admin/studentList/pages/NewStudent",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    name: "Student Accounts",
    path: "/students/:id/accounts",
    component: "modules/admin/studentList/pages/StudentAccount",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    name: "Student Subjects",
    path: "/students/:id/grades",
    component: "modules/admin/studentList/pages/StudentGrades",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    name: "Student Status",
    path: "/students/:id/standing",
    component: "modules/admin/studentList/pages/StudentStanding",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    name: "Student Evaluation",
    path: "/students/:id/evaluation",
    component: "modules/admin/studentList/pages/StudentEvaluation",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    name: "Accounts",
    path: "/accounts",
    component: "modules/admin/cashier/pages/Account",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.cashier],
  },
  {
    name: "View Account",
    path: "/accounts/:id",
    component: "modules/admin/cashier/pages/ViewAccount",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.cashier],
  },
  {
    name: "Payments",
    path: "/payments",
    component: "modules/admin/cashier/pages/Payment",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.cashier],
  },
  {
    name: "Billing",
    path: "/payments/billing/:id",
    component: "modules/admin/cashier/pages/Billing",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.cashier],
  },

  {
    name: "Sms",
    path: "/sms",
    component: "modules/admin/sms/pages/Sms",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator],
  },
  {
    name: "Messages",
    path: "/messages",
    component: "modules/admin/sms/pages/Messages",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator],
  },

  {
    name: "Settings",
    path: "/settings/system",
    component: Setting,
    layout: "/admin",
    allowedRoles: [systemRoles.administrator],
  },
  {
    name: "Roles Permission",
    path: "/settings/roles-permission",
    component: "modules/admin/setting/pages/RolesPermission",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator],
  },
  {
    name: "Permission List",
    path: "/settings/permissions",
    component: "modules/admin/setting/pages/Permissions",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator],
  },
  {
    name: "Roles",
    path: "/settings/roles",
    component: "modules/admin/setting/pages/Roles",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator],
  },
  {
    name: "Subject Offer",
    path: "/settings/subject-offer",
    component: "modules/admin/subjectoffer/pages/SubjectOffer",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    name: "Courses",
    path: "/settings/courses",
    component: "modules/admin/courses/pages/Courses",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },

  {
    path: "/settings/prospectus",
    name: "Prospectus",
    component: Curriculum,
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    path: "/settings/prospectus/edit/:id",
    name: "Prospectus",
    component: "modules/admin/prospectus/pages/Prospectus",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    path: "/settings/duplicate-users",
    name: "Duplicate Users",
    component: DuplicateUser,
    layout: "/admin",
    allowedRoles: [systemRoles.administrator],
  },
  {
    name: "Subject Offer",
    path: "/settings/subject-offer/add",
    component: "modules/admin/subjectoffer/pages/AddOffer",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    name: "Subject Offer",
    path: "/settings/subject-offer/edit/:id",
    component: "modules/admin/subjectoffer/pages/UpdateOffer",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    name: "Subject Categories",
    path: "/settings/subject-categories",
    component: "modules/admin/subject-category/pages/SubjectCategory",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.registrar],
  },
  {
    name: "Fees",
    path: "/settings/fees",
    component: "modules/admin/fees/pages/Fees",
    layout: "/admin",
    allowedRoles: [systemRoles.administrator, systemRoles.cashier],
  },
  {
    name: "Password Reset",
    path: "/settings/password-reset",
    component: PassReset,
    layout: "/admin",
    allowedRoles: [systemRoles.administrator],
  },

  // student routes
  {
    name: "Subject",
    path: "/student/subjects",
    component: Subjects,
    layout: "/admin",
    allowedRoles: [systemRoles.student],
  },
  {
    name: "eClearance",
    path: "/student/eclearance",
    component: EClearance,
    layout: "/admin",
    allowedRoles: [systemRoles.student],
  },
  {
    name: "Grades",
    path: "/student/grades",
    component: Grades,
    layout: "/admin",
    allowedRoles: [systemRoles.student],
  },
  {
    name: "Evaluation",
    path: "/student/evaluation",
    component: "modules/student/pages/Evaluation",
    layout: "/admin",
    allowedRoles: [systemRoles.student],
  },
  {
    name: "Billing",
    path: "/student/billing",
    component: "modules/student/pages/Billing",
    layout: "/admin",
    allowedRoles: [systemRoles.student],
  },

  // // instructor routes
  {
    name: "Subject Loads",
    path: "/instructor/subject-loads",
    component: SubjectLoad,
    layout: "/admin",
    allowedRoles: [systemRoles.full_time, systemRoles.part_time],
  },

  {
    path: "/instructor/subject-loads/roster/:sub_id",
    name: "Subject Roster",
    component: "modules/instructor/pages/SubjectRoster",
    layout: "/admin",
    allowedRoles: [systemRoles.full_time, systemRoles.part_time],
  },

  {
    path: "/instructor/e-clearance",
    name: "EClearance",
    component: InstructorEClearance,
    layout: "/admin",
    allowedRoles: [systemRoles.full_time, systemRoles.part_time],
  },
  {
    path: "/instructor/e-clearance/:sub_id",
    name: "Student Clearance",
    component: "modules/instructor/pages/StudentClearance",
    layout: "/admin",
    allowedRoles: [systemRoles.full_time, systemRoles.part_time],
  },

  {
    path: "/instructor/grades",
    name: "Grades",
    component: InstructorGrades,
    layout: "/admin",
    allowedRoles: [systemRoles.full_time, systemRoles.part_time],
  },

  {
    path: "/instructor/grades/roster/:sub_id",
    name: "Grades Roster",
    component: "modules/instructor/pages/GradeRoster",
    allowedRoles: [systemRoles.full_time, systemRoles.part_time],
    layout: "/admin",
  },
  // {
  //   path: "/instructor/grades/roster/:sub_id/print",
  //   authenticate: false,
  //   path_name: "Grades Roster",
  //   component: "modules/instructor/pages/PrintGrades",
  //   noHeader: true,
  //   allowedRoles: ["Full Time Instructor", "Part Time Instructor"],
  // },

  // end instructor routes
];
