import { combineReducers } from "redux";
import user from "../modules/admin/user/store/reducer";
import employees from "../modules/admin/employee/store/reducer";
import notifications from "../modules/layouts/store/reducer";
import prospectuses from "../modules/admin/prospectus/store/reducer";
import courses from "../modules/admin/courses/store/reducer";
import curriculum from "../modules/admin/curriculum/store/reducer";
import offers from "../modules/admin/subjectoffer/store/reducer";
import fees from "../modules/admin/fees/store/reducer";
import enrollments from "../modules/admin/enrollment/store/reducer";
import informations from "../modules/information/store/reducer";
import settings from "../modules/admin/setting/store/reducer";
import maintenanceInfo from "../layouts/store/reducer";
import subjectCategories from "../modules/admin/subject-category/store/reducer";
import studentTuitions from "../modules/admin/cashier/store/reducer";

const reducers = combineReducers({
  user,
  settings,
  employees,
  notifications,
  prospectuses,
  courses,
  curriculum,
  offers,
  fees,
  enrollments,
  informations,
  subjectCategories,
  studentTuitions,
  maintenanceInfo,
});

export default reducers;
