// import MessengerCustomerChat from "react-messenger-customer-chat";

function MessengerChat() {
  return null;
  // return (
  //   !window.location.origin.includes("localhost") && (
  //     <MessengerCustomerChat
  //       pageId={process.env.REACT_APP_MESSENGER_PAGE_ID}
  //       appId={process.env.REACT_APP_MESSENGER_APP_ID}
  //     />
  //   )
  // );
}

export default MessengerChat;
