export const REQUEST_FETCH_FEES = "REQUEST_FETCH_FEES";
export const FETCH_FEES = "FETCH_FEES";
export const FAIL_FETCH_FEES = "FAIL_FETCH_FEES";

export const REQUEST_CREATE_FEE = "REQUEST_CREATE_FEE";
export const CREATE_FEE = "CREATE_FEE";
export const FAIL_CREATE_FEE = "FAIL_CREATE_FEE";

export const REQUEST_UPDATE_FEE = "REQUEST_UPDATE_FEE";
export const UPDATE_FEE = "UPDATE_FEE";
export const FAIL_UPDATE_FEE = "FAIL_UPDATE_FEE";
