import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import BasicDataTable from "../../../components/BasicDataTable";
import { getLevel, getSemester } from "../../../utils/helpers";
import palletes from "../../../assets/styles/palletes";
import * as service from "../studentService";

const useStyles = makeStyles({
  notCleared: {
    backgroundColor: palletes.error,
    color: "#fff",
    padding: "4px 12px",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  cleared: {
    backgroundColor: palletes.success,
    color: "#fff",
    textAlign: "center",
    padding: "4px 12px",
    whiteSpace: "nowrap",
  },
});

function EClearance() {
  const classes = useStyles();
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData(); // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    setLoading(true);
    service
      .getAllLoads()
      .then((res) => {
        if (res.data.data) {
          setAccounts(res.data.data || []);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const columns = [
    {
      name: "subjectOffer.prospectus.code",
      label: "Code",
    },
    {
      name: "subjectOffer.prospectus.descriptive_title",
      label: "Description",
    },
    {
      name: "subjectOffer.section",
      label: "Section",
    },
    {
      name: "subjectOffer.employee.partial_name",
      label: "Instructor",
    },
    {
      name: "subjectOffer.prospectus.type",
      label: "Type",
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => getStatus(value),
      },
    },
  ];

  const getStatus = (value = 0) => {
    if (parseInt(value) === 0) {
      return <span className={classes.notCleared}>Not Cleared</span>;
    }

    return <span className={classes.cleared}>Cleared</span>;
  };

  return (
    <Fragment>
      {loading ? (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      ) : (
        accounts.map((account, i) => (
          <Box padding={2} key={i}>
            <Box padding={1}>
              {account.course ? (
                <Typography>
                  <span>{account.course.code}: </span>
                  <span>{getLevel(account.level)} - </span>
                  <span>{getSemester(account.semester)} Semester, </span>
                  <span>{account.school_year}</span>
                </Typography>
              ) : (
                <Typography gutterBottom>
                  {`Grade ${account.level}, ${
                    account.school_year
                  }, ${getSemester(account.semester, true)}`}
                </Typography>
              )}
            </Box>
            <BasicDataTable
              withIndex
              data={account.loads || []}
              columns={columns}
            />
          </Box>
        ))
      )}
    </Fragment>
  );
}

export default EClearance;
