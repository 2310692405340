import React, { useEffect, useState } from "react";
import FormField from "../../../../components/FormField";
// import FormSelectField from "components/FormSelectField";
import FormDialog from "../../../../components/FormDialog";
import { Revalidate } from "../../../../utils/helpers";
import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as service from "../service";
// import { useHistory } from "react-router";
import PropTypes from "prop-types";

const validator = Revalidate({
  name: "required",
});

function EditCurriculum(props) {
  const { open, onClose, values = {} } = props;
  // const history = useHistory();

  const dispatch = useDispatch();
  const curriculum = useSelector((state) => state.curriculum);

  const [formValues, setFormValues] = useState({
    values: {
      name: "",
    },
    errors: validator.errors,
  });

  useEffect(() => {
    if (open) {
      setFormValues((prev) => ({
        ...prev,
        values: {
          name: values.name || "",
        },
      }));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const { errors } = validator;

    errors.remove(name);

    setFormValues((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        [name]: value,
      },
    }));

    validator
      .validate(name, value)
      .then(() =>
        setFormValues((prev) => ({ ...prev, errors: validator.errors }))
      );
  };

  const handleSubmit = () => {
    validator.validateAll(formValues.values).then((success) => {
      if (success) {
        dispatch(service.updateCurriculum(formValues.values, values.id)).then(
          (data) => {
            if (data) {
              onClose();
            }
          }
        );
      } else {
        setFormValues((prev) => ({
          ...prev,
          errors: validator.errors,
        }));
      }
    });
  };

  return (
    <FormDialog open={open} onClose={onClose}>
      <DialogTitle>Edit Curriculum</DialogTitle>
      <DialogContent>
        <FormField
          name="name"
          label="Curriculum Year"
          value={formValues.values.name}
          onChange={handleChange}
          errors={formValues.errors}
        />

        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            disabled={curriculum.updating}
          >
            {curriculum.updating ? <CircularProgress /> : "Update"}
          </Button>
        </div>
      </DialogContent>
    </FormDialog>
  );
}

EditCurriculum.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  values: PropTypes.object,
};

export default EditCurriculum;
