import * as types from "./actionTypes";

export function requestGetUser() {
  return {
    type: types.REQUEST_GET_USER,
  };
}

export function getUser(data) {
  return {
    type: types.GET_USER,
    payload: data,
  };
}

export function failedGetUser(data) {
  return {
    type: types.FAILED_GET_USER,
    payload: data,
  };
}
// logout
export function requestLogoutUser() {
  return {
    type: types.REQUEST_LOGOUT_USER,
  };
}

export function failedLogoutUser(data) {
  return {
    type: types.FAILED_LOGOUT_USER,
    payload: data,
  };
}

export function updateProfile(data) {
  return {
    type: types.UPDATE_PROFILE,
    payload: data,
  };
}
