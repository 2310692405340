import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormField from "../../../../components/FormField";
import EditIcon from "@material-ui/icons/Edit";
import * as service from "../service";
import Card from "../../../../components/Card/Card";
import { useHistory, useLocation } from "react-router";
import AddForm from "../components/AddForm";
import BasicDataTable from "../../../../components/BasicDataTable";
import EditCurriculum from "../components/EditCurriculum";
import ListAltIcon from "@material-ui/icons/ListAlt";

const useStyles = makeStyles({
  searchBox: {
    padding: "12px 16px",
  },
  addButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "100%",
    "@media (max-width: 959px)": {
      justifyContent: "flex-start",
    },
  },
});
function Curriculum() {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();

  const curriculum = useSelector((state) => state.curriculum);
  const dispatch = useDispatch();

  const [openAddCurriculum, setOpenAddCurriculum] = useState(false);
  const [openEditCurriculum, setOpenEditCurriculum] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [courses, setCourses] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    limit: 15,
  });

  const columns = [
    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (value, item) => (
          <Fragment>
            <IconButton
              color="primary"
              edge="end"
              size="small"
              onClick={() => handleNavigate(`edit/${value}`)}
            >
              <ListAltIcon />
            </IconButton>
            <IconButton
              color="primary"
              edge="end"
              size="small"
              onClick={() => handleEdit(item)}
            >
              <EditIcon />
            </IconButton>
          </Fragment>
        ),
      },
    },

    {
      name: "course",
      label: "Course",
      options: {
        customBodyRender: (value) => value.code,
      },
    },
    {
      name: "name",
      label: "Curriculum Year",
      options: {
        customBodyRender: (value, item) => {
          return (
            <div>
              <Typography>{value}</Typography>
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    dispatch(service.getAllCurriculum());

    service.getCourses().then((res) => {
      if (res.data) {
        setCourses(res.data.data || []);
      }
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleFilterChange = (name, value) => {
    if (name === "search") {
      dispatch(service.getAllCurriculum({ search: value }));
    }
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEdit = (item) => {
    setEditValues(item);
    setOpenEditCurriculum(true);
  };

  const handleChangePage = (page) => {
    dispatch(service.getAllCurriculum({ ...filters, page: page + 1 }));
  };

  const handleChangeRowsPerPage = (limit) => {
    handleFilterChange("limit", limit);
    dispatch(service.getAllCurriculum({ ...filters, limit }));
  };

  const handleNavigate = (path) => {
    history.push(`${location.pathname}/${path}`);
  };

  return (
    <Card>
      <Box className={classes.searchBox}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormField
              label="Search"
              value={filters.search}
              onChange={(e) => handleFilterChange("search", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.addButton}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenAddCurriculum(true)}
              >
                New Curriculum
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>

      {curriculum.fetching && <LinearProgress />}

      <BasicDataTable
        withPagination
        withIndex
        columns={columns}
        data={curriculum.items || 0}
        page={curriculum.meta.current_page - 1 || 0}
        count={curriculum.meta.total}
        rowsPerPage={filters.limit}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <AddForm
        open={openAddCurriculum}
        onClose={() => setOpenAddCurriculum(false)}
        courses={courses}
      />

      <EditCurriculum
        open={openEditCurriculum}
        onClose={() => setOpenEditCurriculum(false)}
        values={editValues}
      />
    </Card>
  );
}

export default Curriculum;
