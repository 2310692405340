export const REQUEST_FETCH_CURRICULUM = "REQUEST_FETCH_CURRICULUM";
export const FETCH_CURRICULUM = "FETCH_CURRICULUM";
export const FAIL_FETCH_CURRICULUM = "FAIL_FETCH_CURRICULUM";

export const REQUEST_CREATE_CURRICULUM = "REQUEST_CREATE_CURRICULUM";
export const CREATE_CURRICULUM = "CREATE_CURRICULUM";
export const FAIL_CREATE_CURRICULUM = "FAIL_CREATE_CURRICULUM";

export const REQUEST_UPDATE_CURRICULUM = "REQUEST_UPDATE_CURRICULUM";
export const UPDATE_CURRICULUM = "UPDATE_CURRICULUM";
export const FAIL_UPDATE_CURRICULUM = "FAIL_UPDATE_CURRICULUM";
