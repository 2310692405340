import Http from "../../utils/Http";

export const getLoads = () => {
  return Http.get(`/api/student/loads`);
};

export const getAllLoads = () => {
  return Http.get("/api/student/allLoads");
};

export const getAccount = () => {
  return Http.get(`/api/students/billing/account`);
};
