import React, { useState } from "react";
import { Tabs, Tab, Card, CardContent } from "@material-ui/core";
import PasswordReset from "./PasswordReset";
import Logs from "./Logs";

function PassReset() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTab = (e, tab) => {
    setActiveTab(tab);
  };
  return (
    <div>
      <Card>
        <CardContent>
          <Tabs value={activeTab} indicatorColor="primary" onChange={handleTab}>
            <Tab label="User" />
            <Tab label="Logs" />
          </Tabs>

          {activeTab === 0 && (
            <div>
              <PasswordReset />
            </div>
          )}

          {activeTab === 1 && (
            <div>
              <Logs />
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default PassReset;
