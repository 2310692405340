import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  text: {
    fontSize: "1.5em",
    color: "red",
  },
});

function Forbidden() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <WarningIcon color="error" />
      <Typography className={classes.text}>Forbidden</Typography>
    </Box>
  );
}

export default Forbidden;
