import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React from "react";
import FormDialog from "./FormDialog";
import PropTypes from "prop-types";
import ErrorIcon from "@material-ui/icons/Error";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  title: {
    textAlign: "center",
    backgroundColor: "#ff342e",
    color: "#fff",
    padding: 8,
  },
});

function PromptModal({
  open,
  onClose,
  handleConfirm,
  message,
  errorType = "error",
  loading = false,
}) {
  const classes = useStyles();
  return (
    <FormDialog open={open}>
      <DialogTitle className={classes.title}>Confirm</DialogTitle>
      <DialogContent>
        <Typography
          style={{ textTransform: "inherit" }}
          variant="h6"
          align="center"
          color={errorType}
        >
          {errorType === "error" && (
            <>
              <ErrorIcon fontSize="large" color="error" /> <br />
            </>
          )}
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirm}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Confirm"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </Button>
      </DialogActions>
    </FormDialog>
  );
}

PromptModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  message: PropTypes.string,
  errorType: PropTypes.string,
};

export default PromptModal;
