import { Avatar, CircularProgress, Grid, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import * as service from "../service";
import FormField from "../../../../components/FormField";
import { showNofications } from "../../../layouts/store/actionCreators";
import { handleResponseError } from "../../../../utils/helpers";
import { useDispatch } from "react-redux";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import { Fragment } from "react";
import BasicDataTable from "../../../../components/BasicDataTable";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DeleteUser from "../components/DeleteUser";

function DuplicateUser() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [deletting, setDeletting] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [users, setUsers] = useState({
    data: [],
    meta: {},
  });

  const [filters, setFilters] = useState({
    limit: 15,
    search: "",
  });

  useEffect(() => {
    fetchData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (params = {}) => {
    setLoading(true);
    service
      .getDuplicateUsers(params)
      .then((res) => {
        if (res.data) {
          setUsers(res.data);
        }

        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleFilterChange = (name, value) => {
    if (name === "search") {
      fetchData({ search: value });
    }

    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const columns = [
    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (value) => (
          <Fragment>
            <IconButton
              color="primary"
              edge="end"
              size="small"
              title="Delete"
              onClick={() => handleOpenResetModal(value)}
            >
              <DeleteForeverIcon color="error" />
            </IconButton>
          </Fragment>
        ),
      },
    },
    {
      name: "info.student_id_number",
      label: "Student ID",
    },
    {
      name: "image",
      label: " ",
      options: {
        customBodyRender: (value) =>
          value ? (
            <Avatar alt={value} src={value} />
          ) : (
            <Avatar>
              <InsertPhotoIcon />
            </Avatar>
          ),
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "email",
      label: "Email",
    },
  ];

  const handleChangePage = (page) => {
    fetchData({ ...filters, page: page + 1 });
  };

  const handleChangeRowsPerPage = (limit) => {
    handleFilterChange("limit", limit);
    fetchData({ ...filters, limit });
  };

  const handleOpenResetModal = (id) => {
    setDeleteId(id);
    setOpenDeleteModal(true);
  };

  const handleConfirmDeletePassword = () => {
    setDeletting(true);
    service
      .deleteDuplicateUser(deleteId)
      .then((res) => {
        if (res.status === 200) {
          dispatch(showNofications("User Account Successfully Deleted."));
          fetchData();
        }
        setOpenDeleteModal(false);
        setDeletting(false);
      })
      .catch((err) => {
        dispatch(showNofications(handleResponseError(err), "error"));
        setDeletting(false);
      });
  };

  return (
    <div>
      <Card>
        <CardBody>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormField
                label="Search"
                fullWidth
                value={filters.search}
                onChange={(e) => handleFilterChange("search", e.target.value)}
              />
            </Grid>
          </Grid>
        </CardBody>
      </Card>

      {loading ? (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      ) : (
        <BasicDataTable
          withPagination
          withIndex
          columns={columns}
          data={users.data || []}
          page={users.meta.current_page - 1}
          count={users.meta.total || 0}
          rowsPerPage={filters.limit}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      <DeleteUser
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        loading={deletting}
        handleConfirm={handleConfirmDeletePassword}
      />
    </div>
  );
}

export default DuplicateUser;
