import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Switch,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import * as service from "../service";
import EditIcon from "@material-ui/icons/Edit";
import FormField from "../../../../components/FormField";
import ChangeStatusConfirmation from "../components/ChangeStatusConfirmation";
import { showNofications } from "../../../layouts/store/actionCreators";
import { handleResponseError } from "../../../../utils/helpers";
import { useDispatch } from "react-redux";
import AddForm from "../components/AddForm";
import UpdateForm from "../components/UpdateForm";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";
import { Fragment } from "react";
import BasicDataTable from "../../../../components/BasicDataTable";

const useStyles = makeStyles({
  addButtonBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",

    "@media (max-width: 959px)": {
      justifyContent: "flex-start",
    },
  },
});
function User() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [allRoles, setAllRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [openCreateForm, setOpenCreateForm] = useState(false);
  const [
    openConfirmChangeStatusmModal,
    setOpenConfirmChangeStatusmModal,
  ] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [status, setStatus] = useState({
    status: "",
    id: "",
  });
  const [users, setUsers] = useState({
    data: [],
    meta: {},
  });

  const [filters, setFilters] = useState({
    limit: 15,
    search: "",
  });

  const [openUpdateUser, setOpenUpdateUser] = useState(false);
  const [updateValues, setUpdateValues] = useState({});

  useEffect(() => {
    const localFilter = JSON.parse(localStorage.getItem("users-filter"));

    if (localFilter) {
      setFilters((prev) => ({
        ...prev,
        ...localFilter,
      }));
    }

    fetchData(localFilter || {});

    service.getAllRoles().then((res) => {
      if (res.data) {
        setAllRoles(res.data.map((role) => role.name));
      }
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = (params = {}) => {
    const newFilter = {
      ...filters,
      ...params,
    };

    localStorage.setItem("users-filter", JSON.stringify(newFilter));

    setLoading(true);
    service
      .getAllUsers(newFilter)
      .then((res) => {
        if (res.data) {
          setUsers(res.data);
        }

        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleFilterChange = (name, value) => {
    if (name === "search") {
      fetchData({ search: value });
    }

    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const columns = [
    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (value) => (
          <Fragment>
            <IconButton
              color="primary"
              edge="end"
              size="small"
              onClick={() => handleOpenUpdateForm(value)}
            >
              <EditIcon />
            </IconButton>
          </Fragment>
        ),
      },
    },
    {
      name: "roles",
      label: "Roles",
      options: {
        customBodyRender: (value) => (value && value.join(",")) || "",
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value, rowData) => (
          <Switch
            onChange={(e) => handleStatusUpdate(e, rowData.id)}
            checked={parseInt(value) === 1}
            color="primary"
          />
        ),
      },
    },
    {
      name: "image",
      label: " ",
      options: {
        customBodyRender: (value) =>
          value ? (
            <Avatar alt={value} src={value} />
          ) : (
            <Avatar>
              <InsertPhotoIcon />
            </Avatar>
          ),
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "info",
      label: "Personal Email",
      options: {
        customBodyRender: (value) => (value && value.personal_email) || "",
      },
    },
    {
      name: "temp_password",
      label: "Temporary Password",
    },
  ];

  const handleChangePage = (page) => {
    fetchData({ ...filters, page: page + 1 });
  };

  const handleChangeRowsPerPage = (limit) => {
    handleFilterChange("limit", limit);
    fetchData({ ...filters, limit });
  };

  const handleStatusUpdate = (e, id) => {
    const status = e.target.checked ? 1 : 0;

    setStatus({ status, id });
    setOpenConfirmChangeStatusmModal(true);
  };

  const handleConfirmChangeStatus = () => {
    setChangeStatusLoading(true);
    service
      .updateUserStatus({ status: status.status }, status.id)
      .then((res) => {
        if (res.data.data) {
          setUsers((prev) => ({
            ...prev,
            data: prev.data.map((user) => {
              if (user.id === res.data.data.id) {
                return res.data.data;
              }

              return user;
            }),
          }));
        }

        setChangeStatusLoading(false);
        setOpenConfirmChangeStatusmModal(false);
      })
      .catch(() => setChangeStatusLoading(false));
  };

  const handleCreateUser = (values) => {
    setCreating(true);
    service
      .createUser(values)
      .then((res) => {
        if (res.data.data) {
          setUsers((prev) => ({
            ...prev,
            data: [res.data.data, ...prev.data],
          }));

          dispatch(showNofications("User Successfully Created"));
          setOpenCreateForm(false);
        }

        setCreating(false);
      })
      .catch((err) => {
        setCreating(false);
        dispatch(showNofications(handleResponseError(err), "error"));
      });
  };

  const handleUpdateUser = (values) => {
    setUpdating(true);
    service
      .updateUser(values, updateValues.id)
      .then((res) => {
        if (res.data.data) {
          setUsers((prev) => ({
            ...prev,
            data: prev.data.map((data) =>
              data.id === res.data.data.id ? res.data.data : data
            ),
          }));

          dispatch(showNofications("User Successfully Updated"));
          setOpenUpdateUser(false);
        }

        setUpdating(false);
      })
      .catch((err) => {
        setUpdating(false);
        dispatch(showNofications(handleResponseError(err), "error"));
      });
  };

  const handleOpenAddUser = () => {
    setOpenCreateForm(true);
  };

  const handleOpenUpdateForm = async (id) => {
    let items = users.data.filter((emp) => emp.id === parseInt(id));
    await setUpdateValues((items && items[0]) || {});
    setOpenUpdateUser(true);
  };

  return (
    <div>
      <Card>
        <CardBody>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormField
                label="Search"
                fullWidth
                value={filters.search}
                onChange={(e) => handleFilterChange("search", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.addButtonBox}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenAddUser}
                >
                  Add New User
                </Button>
              </div>
            </Grid>
          </Grid>
        </CardBody>
      </Card>

      {loading ? (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      ) : (
        <BasicDataTable
          withPagination
          withIndex
          columns={columns}
          data={users.data || []}
          page={users.meta.current_page - 1}
          count={users.meta.total || 0}
          rowsPerPage={filters.limit}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      <ChangeStatusConfirmation
        handleConfirm={handleConfirmChangeStatus}
        open={openConfirmChangeStatusmModal}
        onClose={() => setOpenConfirmChangeStatusmModal(false)}
        loading={changeStatusLoading}
      />
      <AddForm
        open={openCreateForm}
        onClose={() => setOpenCreateForm(false)}
        allRoles={allRoles}
        loading={creating}
        handleCreate={handleCreateUser}
      />

      <UpdateForm
        open={openUpdateUser}
        onClose={() => setOpenUpdateUser(false)}
        allRoles={allRoles}
        values={updateValues}
        loading={updating}
        handleUpdate={handleUpdateUser}
      />
    </div>
  );
}

export default User;
